import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { paramResponseActions } from '../../actions'
import ParamResponse from './paramResponse/ParamResponse'

class ParamResponseContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			editAction: props.editAction,
			paramResponse: ""
		};
	}

	componentWillMount(){
		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		let paramResponse = nextProps.paramResponse
		let editAction = nextProps.editAction

		let newState = update( this.state, {
			paramResponse: {$set: paramResponse},
			editAction: {$set: editAction}
		})

		this.setState( newState )
	}

	handleChange = (event) => {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			paramResponse:{
				response: { [name]: {$set: value} }
			}
		});

		this.setState( newState );
	}

	handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;

        //dispatch(paramResponseActions.saveField( this.state.paramResponse.response.id, name, value )) 		
        dispatch(paramResponseActions.saveValue( 
        	this.state.paramResponse.response.response_id, 
        	this.state.paramResponse.response.param_id, value )) 		

  	}
	

	render(){
		const {paramResponse,editAction} = this.state		
		if( !paramResponse ) return false

		return <ParamResponse
			editAction={editAction}	
			handleChange={this.handleChange}
			handleSelectChange={this.handleSelectChange}
			handleBlur={this.handleBlur}
			paramResponse={paramResponse} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  		auth: state.auth,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ParamResponseContainer);
