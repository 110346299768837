import { trackService } from '../services';
import { alertActions } from './';


export const trackActions = {
    load,
    loadByProjectId,
    loadByProjectIdFilter,    
    add,
    save,
    saveField,
    encrypt,
    _delete,
}

export const TRACK_LOAD_REQUEST = 'TRACK_LOAD_REQUEST'
export const TRACK_LOAD_SUCCESS = 'TRACK_LOAD_SUCCESS'
export const TRACK_LOAD_FAILURE = 'TRACK_LOAD_FAILURE'

function load() {
    return dispatch => {
        dispatch(request( ));   
        trackService.load()
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: TRACK_LOAD_REQUEST } 
    }
    function success(rows) { return { type: TRACK_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: TRACK_LOAD_FAILURE, error } }
}

export const TRACK_LOAD_BY_PROJECT_ID_REQUEST = 'TRACK_LOAD_BY_PROJECT_ID_REQUEST'
export const TRACK_LOAD_BY_PROJECT_ID_SUCCESS = 'TRACK_LOAD_BY_PROJECT_ID_SUCCESS'
export const TRACK_LOAD_BY_PROJECT_ID_FAILURE = 'TRACK_LOAD_BY_PROJECT_ID_FAILURE'

function loadByProjectId(projectId) {
    return dispatch => {
        dispatch(request( projectId ));   
        trackService.loadByProjectId( projectId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( projectId ) { 
        return { type: TRACK_LOAD_BY_PROJECT_ID_REQUEST } 
    }
    function success(rows) { return { type: TRACK_LOAD_BY_PROJECT_ID_SUCCESS, rows } }
    function failure(error) { return { type: TRACK_LOAD_BY_PROJECT_ID_FAILURE, error } }
}

export const TRACK_ADD_REQUEST = 'TRACK_ADD_REQUEST'
export const TRACK_ADD_SUCCESS = 'TRACK_ADD_SUCCESS'
export const TRACK_ADD_FAILURE = 'TRACK_ADD_FAILURE'

function add( userId, project ) {
    return dispatch => {
        dispatch(request( userId, project ));   
        trackService.add(project)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TRACK_ADD_REQUEST, userId, project } 
    }
    function success(rows) { return { type: TRACK_ADD_SUCCESS, rows } }
    function failure(error) { return { type: TRACK_ADD_FAILURE, error } }
}

export const TRACK_SAVE_REQUEST = 'TRACK_SAVE_REQUEST'
export const TRACK_SAVE_SUCCESS = 'TRACK_SAVE_SUCCESS'
export const TRACK_SAVE_FAILURE = 'TRACK_SAVE_FAILURE'

function save( task ) {
    return dispatch => {
        dispatch(request( task ));   
        trackService.save(task)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TRACK_SAVE_REQUEST, task } 
    }
    function success(rows) { return { type: TRACK_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: TRACK_SAVE_FAILURE, error } }
}

export const TRACK_SAVE_FIELD_REQUEST = 'TRACK_SAVE_FIELD_REQUEST'
export const TRACK_SAVE_FIELD_SUCCESS = 'TRACK_SAVE_FIELD_SUCCESS'
export const TRACK_SAVE_FIELD_FAILURE = 'TRACK_SAVE_FIELD_FAILURE'

function saveField( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        trackService.saveField(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TRACK_SAVE_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: TRACK_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: TRACK_SAVE_FAILURE, error } }
}

export const TRACK_ENCRYPT_REQUEST = 'TRACK_ENCRYPT_REQUEST'
export const TRACK_ENCRYPT_SUCCESS = 'TRACK_ENCRYPT_SUCCESS'
export const TRACK_ENCRYPT_FAILURE = 'TRACK_ENCRYPT_FAILURE'

function encrypt( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        trackService.encrypt(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: TRACK_ENCRYPT_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: TRACK_ENCRYPT_SUCCESS, rows } }
    function failure(error) { return { type: TRACK_ENCRYPT_FAILURE, error } }
}

export const TRACK_DELETE_REQUEST = 'TRACK_DELETE_REQUEST'
export const TRACK_DELETE_SUCCESS = 'TRACK_DELETE_SUCCESS'
export const TRACK_DELETE_FAILURE = 'TRACK_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        trackService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: TRACK_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: TRACK_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: TRACK_DELETE_FAILURE, error } }
}


export const TRACK_FILTER_REQUEST = 'TRACK_FILTER_REQUEST'
export const TRACK_FILTER_SUCCESS = 'TRACK_FILTER_SUCCESS'
export const TRACK_FILTER_FAILURE = 'TRACK_FILTER_FAILURE'

function loadByProjectIdFilter( projectId, sSearch) {
    return dispatch => {
        dispatch(request( projectId, sSearch ));   
        trackService.loadByProjectIdFilter( projectId, sSearch )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            )
    }

    function request( ) { 
        return { type: TRACK_FILTER_REQUEST } 
    }
    function success(rows) { return { type: TRACK_FILTER_SUCCESS, rows } }
    function failure(error) { return { type: TRACK_FILTER_FAILURE, error } }
}