import { projectService } from '../services';
import { alertActions } from './';


export const projectActions = {
    load,
    add,
    save,
    saveField,
    _delete,
}

export const PROJECT_LOAD_REQUEST = 'PROJECT_LOAD_REQUEST'
export const PROJECT_LOAD_SUCCESS = 'PROJECT_LOAD_SUCCESS'
export const PROJECT_LOAD_FAILURE = 'PROJECT_LOAD_FAILURE'

function load() {
    return dispatch => {
        dispatch(request( ));   
        projectService.load()
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: PROJECT_LOAD_REQUEST } 
    }
    function success(rows) { return { type: PROJECT_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: PROJECT_LOAD_FAILURE, error } }
}

export const PROJECT_ADD_REQUEST = 'PROJECT_ADD_REQUEST'
export const PROJECT_ADD_SUCCESS = 'PROJECT_ADD_SUCCESS'
export const PROJECT_ADD_FAILURE = 'PROJECT_ADD_FAILURE'

function add( userId, project ) {
    return dispatch => {
        dispatch(request( userId, project ));   
        projectService.add(project)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: PROJECT_ADD_REQUEST, userId, project } 
    }
    function success(rows) { return { type: PROJECT_ADD_SUCCESS, rows } }
    function failure(error) { return { type: PROJECT_ADD_FAILURE, error } }
}

export const PROJECT_SAVE_REQUEST = 'PROJECT_SAVE_REQUEST'
export const PROJECT_SAVE_SUCCESS = 'PROJECT_SAVE_SUCCESS'
export const PROJECT_SAVE_FAILURE = 'PROJECT_SAVE_FAILURE'

function save( task ) {
    return dispatch => {
        dispatch(request( task ));   
        projectService.save(task)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: PROJECT_SAVE_REQUEST, task } 
    }
    function success(rows) { return { type: PROJECT_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: PROJECT_SAVE_FAILURE, error } }
}

export const PROJECT_SAVE_FIELD_REQUEST = 'PROJECT_SAVE_FIELD__REQUEST'
export const PROJECT_SAVE_FIELD__SUCCESS = 'PROJECT_SAVE_FIELD__SUCCESS'
export const PROJECT_SAVE_FIELD__FAILURE = 'PROJECT_SAVE_FIELD__FAILURE'

function saveField( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        projectService.saveField(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: PROJECT_SAVE_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: PROJECT_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: PROJECT_SAVE_FAILURE, error } }
}

export const PROJECT_DELETE_REQUEST = 'PROJECT_DELETE_REQUEST'
export const PROJECT_DELETE_SUCCESS = 'PROJECT_DELETE_SUCCESS'
export const PROJECT_DELETE_FAILURE = 'PROJECT_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        projectService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: PROJECT_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: PROJECT_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: PROJECT_DELETE_FAILURE, error } }
}