import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid';


import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {  NavLink } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import IconButton from '@material-ui/core/IconButton';

import Fab from '@material-ui/core/Fab';

import ProjectTypeAddContainer from '../../projectTypeAddContainer/ProjectTypeAddContainer'
import ProjectTypeEditContainer from '../../projectTypeEditContainer/ProjectTypeEditContainer'
import ProjectTypeDeleteContainer from '../../ProjectTypeDeleteContainer'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = theme => ({});

const ProjectTypeList = (props) => {
    const {projectTypeList,classes} = props

    if( !projectTypeList ) return false

    return <div className={classes.root}>
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody> 
                <ProjectTypeAddContainer />          
                {Object.keys(projectTypeList).map( (i) => {
                    let type  = projectTypeList[i]
                    return <ProjectTypeEditContainer projectType={type} />  
                })}
            </tbody>
        </table>
    </div>
}

export default withStyles(styles)(ProjectTypeList);