import {fetchApi} from '../helpers';

export const paramResponseService = {
	load,
    loadByProjectId,
	add,
    saveField,
    saveValue,
    _delete,
};

function load() {
    const res  = fetchApi('api/paramResponseLoad', {
    }) 

    return res
}

function loadByProjectId( projectId ) {
    const res  = fetchApi('api/paramResponseLoadByProjectId', {
        projectId: projectId
    }) 

    return res
}

function add( track ) {
    const res  = fetchApi('api/paramResponseAdd', {
        track: track,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/paramResponseSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}
function saveValue(responseId, paramId, value) {
    const res  = fetchApi('api/paramResponseSaveValue', {
        responseId: responseId,
        paramId: paramId,
        value: value,
    }) 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/paramResponseDelete', {
        id: id,
    }) 

    return res
}