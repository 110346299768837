import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Switch, Route, Redirect } from 'react-router-dom';

import Auth from '../modules/Auth';

import { PrivateRoute } from './PrivateRoute';

import LoginContainer from '../components/loginContainer/LoginContainer';

//import ProjectPage from '../components/pages/ProjectPage';
import ProjectListPage from '../components/pages/ProjectListPage';
import TrackPage from '../components/pages/TrackPage';

import 'typeface-roboto'

import { alertActions } from '../actions';
import { history } from '../helpers/history';

/*
import { MuiThemeProvider } from '@material-ui/core/styles';
import { theme } from '../themes/main';
*/

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearchPlus, faTrashAlt, faEdit, 
  faPlus, faClock, faBars, faAngleDown, faPencilAlt,
  faSignOutAlt,  faTasks, faLock, faUnlock, faStar } from '@fortawesome/free-solid-svg-icons'

library.add( faSearchPlus, faTrashAlt, faEdit,
  faPlus, faClock, faBars, faAngleDown, faPencilAlt, 
  faSignOutAlt,  faTasks, faLock, faUnlock, faStar )


class App extends Component { 

  	constructor(props) {
   	super(props);

    	const { dispatch } = this.props;
    	history.listen((location, action) => {
      	// clear alert on location change
       	dispatch(alertActions.clear());
    	});
   }

   render(){
      return <Switch>
          <Route exact path="/login" component={LoginContainer} />
          <Route exact path="/logout" render={ () => {
                Auth.deauthenticateUser()
                return <Redirect to={{ pathname: '/login', state: { from: this.props.location } }} />             
              }
            }
          />

			    <PrivateRoute exact path="/" component={ProjectListPage} />
          <PrivateRoute exact path="/t/:id" component={TrackPage} />
          
			</Switch>
    }
}

/*<PrivateRoute exact path="/project/" component={ProjectPage} />
          <PrivateRoute exact path="/project/:id" component={ProjectPage} />*/
const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;
  return {
    auth: authentication,
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));  