import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import TrackContainer from '../../trackContainer/TrackContainer'
import TrackAddContainer from '../../trackAddContainer/TrackAddContainer'

import Button from '@material-ui/core/Button';

import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Dialog from '@material-ui/core/Dialog';


var moment = require("moment");

const styles = theme => ({
  	root: {
    	width: '100%',
    	maxWidth: 360,
  	},
  	inline: {
    	display: 'inline',
  	},
  	container: {
		display: 'flex',
		height: '100vh',
		overflow: 'hidden',
//		marginTop: theme.spacing(7),
	},
	side: {
  		overflow: 'auto',
  		overflowX: 'hidden',
  		height: '100vh',
  		//backgroundColor: theme.palette.primary.light,  		
  		boxShadow:  theme.shadows[3],  		
  		position: "relative"
  	},
  	main: {
  		height: '100vh',
  		overflow: 'auto',
  		backgroundColor: theme.palette.background.white,  		
  	},
  	fab: {
  		position: 'absolute',
    	bottom: theme.spacing(2),
    	right: theme.spacing(2),
    	margin: theme.spacing(1),
    	'&:hover': {
      		backgroundColor: theme.palette.primary.light,
    	},
  	},

  	buttonTop: {
  		position: "fixed",
  		bottom: theme.spacing(2),
  		left: theme.spacing(2),
  		zIndex: 999,
  		'&:hover': {
      		backgroundColor: theme.palette.primary.light,
    	},
  	},

});

class TrackList extends Component {    	

	render() {
		const {project,trackList,classes,currentTrack,handleClickTrackView,handleClickImportance} = this.props

		if( !project || !trackList) return false
		

		return <div> 			
			<Grid container className={classes.container} justify="center">		
				<Grid item xs={12} sm={2} className={classes.side}>
					<div style={{position: "relative"}}>
					<Fab  color="primary" 
						onClick={this.props.handleModalOpen}
						className={classes.buttonTop}>
                        <FontAwesomeIcon icon="plus" />
					</Fab>
					</div>

					<List dense={true}>
						{
							Object.keys(trackList)
								.map( i => trackList[i])
								.sort((a, b) => {
									if (a.importance > b.importance) return -1
									if (a.importance < b.importance) return 1
									if( a.date > b.date ) return -1
									if( a.date < b.date ) return 1
									return 0
								})
								.map( (t) => {
									return <ListItem key={t.id} dense button onClick={() => handleClickTrackView(t.id)}>
										<ListItemIcon onClick={() => handleClickImportance(t.id)}>
											<Checkbox
			                					edge="start"
			                					checked={(t.importance === 1)?true:false}
			                					tabIndex={-1}
			                					disableRipple
			                					inputProps={{ 'aria-labelledby': t.id }}
			              					/>
										</ListItemIcon>
										<ListItemText primary={t.title} secondary={moment(t.date).format("MMMM DD, YYYY")} />
									</ListItem>
								}
							)			
						}
					</List>
				</Grid>
				<Grid item xs={12} sm={10} className={classes.main}>
					{(currentTrack)?<TrackContainer track={currentTrack} key={currentTrack.id} />:""}			
				</Grid>
			</Grid>
			 
			<Dialog
				open={this.props.modalOpen}
				onClose={this.props.handleModalClose}
				aria-labelledby="form-dialog-title"
				>
				<TrackAddContainer projectId={project.id} handleClose={this.props.handleModalClose} />
			</Dialog>
    	</div>
	}
}

export default withStyles(styles)(TrackList);  