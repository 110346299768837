import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { responseActions } from '../../../actions'
import ResponseAdd from './responseAdd/ResponseAdd'

class ResponseAddContainer extends Component { 
	constructor(props) {
		super(props)

		this.state = {
			response: this.emptyResponse(),	
			paramResponse: ""		
		}
	}
	
	emptyResponse(){
		let emptyResponse = { 
			track_id: this.props.trackId,
			title: "",
			description: "",
			date: new Date(),
		}
		return emptyResponse;
	}

	setEmptyResponse(){
		let newState = update( this.state, {
			response: {$set: this.emptyResponse()}
		});		
		this.setState( newState );
	}

	componentWillMount(){
		this.componentWillReceiveProps( this.props )
	}

	componentWillReceiveProps( nextProps ){
		let paramResponse = []
		Object.keys(nextProps.paramList).map( (i) => {
			let param = nextProps.paramList[i]
			paramResponse.push({
				param: param,
				param_id: param.id,
				value: "",
			})
			return true
		})
		this.setState({
			paramResponse: paramResponse
		})
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		
		let newState = update( this.state, {
			response: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleParamChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const param_id = parseInt(target.getAttribute('param_id'),10);
		
		let paramResponse = this.state.paramResponse.map( (param) => {
			if( param.param.id === param_id ){
				param.value = value
			}
			return param
		})

		let newState = update( this.state, {
			paramResponse: {$set: paramResponse},
		});

		this.setState( newState );
	}

	handleContentStateChange: Function = (contentState) => {
		let newState = update( this.state, {
			descriptionState: {$set: contentState },
			response: { description: {$set: JSON.stringify(contentState) } },
		})

		this.setState( newState );
  	}

	handleSubmit = (event) => {
		const {dispatch,auth} = this.props
		dispatch( responseActions.add( auth.user, this.state.response) )
      	this.setEmptyResponse()
	}


	render(){

		return  <ResponseAdd 
         	response={this.state.response}
         	paramResponse={this.state.paramResponse}
			handleChange={this.handleChange}
			handleContentStateChange={this.handleContentStateChange}
			handleParamChange={this.handleParamChange}
			handleSubmit={this.handleSubmit} 
			handleClose={this.props.handleClose} 
         />
	}
}

const mapStateToProps = (state, ownProps) => {
  return {
	  param: state.param,  
	  auth: state.auth,     
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( ResponseAddContainer );
