import React, {Component} from 'react';
import { connect } from 'react-redux'

import { authActions } from '../../actions';
import Login from './login/Login.js';

class LoginContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      user: {
        email: '',
        password: ''
      }
    };

    this.processForm = this.processForm.bind(this);
    this.changeUser = this.changeUser.bind(this);
  }

  processForm(event) {
    event.preventDefault();

    console.log('email:', this.state.user.email);
    console.log('password:', this.state.user.password);

    this.props.dispatch( authActions.login(this.state.user.email, this.state.user.password) )
  }

  changeUser(event) {
    const field = event.target.name;
    const user = this.state.user;
    user[field] = event.target.value;

    this.setState({
      user
    });
  }

  render() {
    return (
      <Login
        onSubmit={this.processForm}
        onChange={this.changeUser}
        errors={this.state.errors}
        user={this.state.user}
      />
    );
  }

}

const mapStateToProps = (state,ownProps)  => {  
  return {
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

LoginContainer = connect(mapStateToProps,mapDispatchToProps)(LoginContainer)

export default LoginContainer