import React, {Component} from 'react';
import PageGeneralContainer from '../pageGeneralContainer/PageGeneralContainer';

import TrackMenuContainer from '../trackMenuContainer/TrackMenuContainer';
import ProjectListContainer from '../project/projectListContainer/ProjectListContainer';


class ProjectListPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			id: "",
		}
    }

	componentDidMount(){
		this.setState({
			id: this.props.match.params.id,
		})
	}

	componentWillReceiveProps(nextProps){
		this.setState({
			id: nextProps.match.params.id,
		})
	}

	render(){
		const menu = <TrackMenuContainer />
		const main = <ProjectListContainer />	    
			
		return <PageGeneralContainer sidebar={menu} main={main} /> 
	}
}

export default ProjectListPage;