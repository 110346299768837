import React, {Component} from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


import {paramType} from '../../../constants';
import ParamDeleteContainer from '../ParamDeleteContainer';

class Param extends Component {    	
	render() {
		if( !this.props.param) return false
        const {param,handleChange,handleBlur,editAction} = this.props
        
        let paramRes = []
        /*if( this.props.response ){
            let responseList = this.props.response
            Object.keys(responseList).map( (i) => {
                let response  =  responseList[i]
                Object.keys(response.params).map( (respId) => {
                    if( parseInt(respId) === parseInt(param.id) ){
                        paramRes.push(response.params[respId])
                    }
                })
            })
        }*/
        let paramElem = ""
        if( editAction ){
            paramElem = <TableRow key={param.id}>
                <TableCell>
                    <Select
                        value={param.type}
                        onChange={this.handleChange}
                        inputProps={{
                            name: 'type',
                        }}>
                        <MenuItem value=""><em>None</em></MenuItem>
                        {Object.keys(paramType).map( (i) => <MenuItem key={i} value={i}>{paramType[i].label}</MenuItem>)}
                    </Select>
                </TableCell>
                <TableCell>
                    <TextField
                        label="Name"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={param.name} 
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        label="Value"
                        name="value"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={param.value} 
                    />
                </TableCell>
                <TableCell>
                    <ParamDeleteContainer id={param.id} />
                </TableCell>
            </TableRow>
        }
        else{
            paramElem = <TableRow key={param.id}>
                <TableCell>{param.name}</TableCell>
                <TableCell>{param.value}</TableCell>
                <TableCell>{paramRes.map( (r) => <span key={r.id} >-> {r.value}</span>)}</TableCell>
            </TableRow>

        }
        
        return paramElem
	}
}

export default Param;  