import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { trackActions,paramActions,responseActions,paramResponseActions } from '../../../actions'

import TrackList from './trackList/TrackList'

class TrackListContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			trackList: [],
			modalOpen: false,
			projectId: "",
			currentTrack: ""
		};
	}

	componentWillMount(){

		this.setState({
			projectId: this.props.projectId
		})
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch} = this.props	
		if( !nextProps.projectId) return false
		
		if( !nextProps.track.isFetching && !nextProps.track.isError  && (!nextProps.track.list || !nextProps.track.list[nextProps.projectId] ) ){
			dispatch( trackActions.loadByProjectId( nextProps.projectId ) )
			dispatch( paramActions.loadByProjectId( nextProps.projectId ) )
			dispatch( responseActions.loadByProjectId( nextProps.projectId ) )
			dispatch( paramResponseActions.loadByProjectId( nextProps.projectId ) )
		}		

		this.setState({
			projectId: nextProps.projectId
		})
	}

	modalOpen = () => {	
		this.setState({
			modalOpen: true,
		})
	}

	modalClose = () => {

		this.setState({
			modalOpen: false,
		})
	}

	handleClickTrackView = (trackId) => {
		const {projectId} = this.state
		const currentTrack = this.props.track.list[projectId][trackId]
		
		let newState = update( this.state, {
			currentTrack: {$set: currentTrack},
		});

		this.setState( newState )

	}

	handleClickImportance = (trackId) => {
		const {projectId} = this.state
		let currentTrack = this.props.track.list[projectId][trackId]
		const {dispatch} = this.props

		if( currentTrack.importance === 1 ){
			currentTrack.importance = 0
		}else{
			currentTrack.importance = 1
		}


        dispatch(trackActions.saveField( currentTrack.id, "importance", currentTrack.importance) )

		let newState = update( this.state, {
			currentTrack: {$set: currentTrack},
		});

		this.setState( newState )

	}

	render(){
		if( !this.props.project.list ) return false

		const projectList = this.props.project.list
		let project = ""
		const projectId = parseInt(this.props.projectId,10)

		let trackList = {}

		if( !this.props.track || !this.props.track.list || !this.props.track.list[projectId] ){
			trackList = {}
		}else{
			trackList = this.props.track.list[projectId]
		}
			
		Object.keys(projectList).map( (p) => {
			if( parseInt(p,10) === projectId ){
				project = projectList[p]				
			}
			return true
		})


			
		return <TrackList project={project}  
			trackList={trackList}
			currentTrack={this.state.currentTrack}
			handleClickAdd={this.handleClickAdd}
			handleClickTrackView={this.handleClickTrackView}
			handleClickImportance={this.handleClickImportance}
			handleModalOpen={this.modalOpen} 
			handleModalClose={this.modalClose} 
			modalOpen={this.state.modalOpen} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
	  	project: state.project,     
	  	track: state.track, 
	  	param: state.param,
	  	response: state.response,     
	  	paramResponse: state.paramResponse,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackListContainer);
