import React, {Component} from 'react';

import { Editor } from 'react-draft-wysiwyg';

import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

import classnames from 'classnames';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';

import Divider from '@material-ui/core/Divider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TextField from '@material-ui/core/TextField';

import ResponseDeleteContainer from '../../ResponseDeleteContainer';
import ParamResponseContainer from '../../../paramResponseContainer/ParamResponseContainer';

import draftToHtml from 'draftjs-to-html';

import Typography from '@material-ui/core/Typography';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

var moment = require("moment");

const styles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    flexBasis: '50%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class Response extends Component {    	
	render() {
		if( !this.props.response) return false
        const {classes,response,handleChange,handleBlur,
            descriptionState,handleContentStateChange,handleContentStateBlur,
            responseEditOpen,handleResponseEditOpen,handleResponseEditClose} = this.props
                       

        let responseHtml = ""
        try {
            responseHtml = draftToHtml(JSON.parse(response.description))
        } catch (e) {
            responseHtml = response.description
        }

        return <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<FontAwesomeIcon icon="angle-down" />}>
                <Typography className={classes.heading}>
                    {response.title}
                    <br/>
                    <small>{moment(response.date).format("MMMM DD, YYYY")}</small>
                </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                <div className={classnames(classes.body1,'w-100')}>             
                    <div dangerouslySetInnerHTML={{ __html: responseHtml }}></div>
                </div>                
                
            </ExpansionPanelDetails>
            <ExpansionPanelDetails className={classes.details}>
                <Table className="w-100">
                    <TableBody>
                        {response.param.map( (pl ) => {
                            return <ParamResponseContainer editAction={false} 
                                paramResponse={pl}  key={pl.response.param_id + "-"+ response.id} />
                        })}
                    </TableBody>
                </Table>
            </ExpansionPanelDetails>
            
            <Divider />

            <ExpansionPanelActions>
                    <IconButton onClick={handleResponseEditOpen}>
                        <FontAwesomeIcon icon="pencil-alt" />
                    </IconButton>
                    <ResponseDeleteContainer id={response.id}/>
            </ExpansionPanelActions>
            <Dialog
                open={responseEditOpen}
                onClose={handleResponseEditClose}
                scroll="body"
                maxWidth="md">
                <DialogContent>
                    <TextField
                        className="w-100"
                        name="title"
                        label="Title"
                        onChange={handleChange}
                        onBlur={handleBlur} 
                        value={response.title} 
                    />    
                    <div  style={{marginTop: '12px',marginBottom: '24px',width: '90%',height: '200px'}}>
                        <Editor
                            toolbarHidden="true"
                            initialContentState={descriptionState}
                            wrapperClassName="form-control w-100 h-100" 
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onContentStateChange={handleContentStateChange}
                            onBlur={handleContentStateBlur} />
                    </div>
                    <TextField
                        name="date"
                        label="Date"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className=""
                        onChange={handleChange}
                        onBlur={handleBlur} 
                        value={moment(response.date).format(moment.HTML5_FMT.DATE)} 
                    />  
                    <Table className="w-100">
                        <TableBody>
                        {response.param.map( (pl) => {
                            return <ParamResponseContainer key={pl.response.param_id + "-"+ response.id} editAction={true} 
                                paramResponse={pl}  />
                        })}
                        </TableBody>
                    </Table>        
                </DialogContent>
            </Dialog>
        </ExpansionPanel>
	}
}

export default withStyles(styles)(Response);  