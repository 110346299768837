import React, {Component} from 'react';
import classnames from 'classnames';

import {projectType} from '../../../../constants/';


import ProjectTypeDeleteContainer from '../../../projectType/ProjectTypeDeleteContainer'



import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import Favorite from '@material-ui/icons/Favorite';

import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import {Visibility, VisibilityOff} from '@material-ui/icons/';

const styles = theme => ({
  	container: {
   		margin: theme.spacing(0),
  	},
  	textField: {
    	margin: theme.spacing(2),
  	},
  	formControl: {
   		margin: theme.spacing(2),
   		minWidth: 120,
  	},
});

class ProjectTypeEdit extends Component {    	
	
	render() {
		if( !this.props.projectType) return false
		const {projectType, classes,
			handleChange, handleBlur } = this.props

		return <tr className={classnames(classes.container)}>
         <td>
       		<TextField
       			label="Name"
       			className={classes.textField}
       			name="name"
       			placeholder="Name" 
   	        	onChange={handleChange} 
   	         	onBlur={handleBlur} 
   	         	value={projectType.name} />
         </td>
         <td>
            <ProjectTypeDeleteContainer id={projectType.id} />
         </td>
      </tr>
	}
}

export default withStyles(styles)(ProjectTypeEdit);