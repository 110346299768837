import {fetchApi} from '../helpers';

export const projectService = {
	load,
	add,
    saveField,
    _delete,
};

function load() {
    const res  = fetchApi('api/projectLoad', {
    }) 

    return res
}

function add( project ) {
    const res  = fetchApi('api/projectAdd', {
        project: project,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/projectSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/projectDelete', {
        id: id,
    }) 

    return res
}