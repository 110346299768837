import React from 'react';

import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	root: {
		marginTop: theme.spacing(4)
	},
});

const ProjectType = (props) => {
	const {projectType,classes} = props

	if( !projectType ) return false

	return <span>{projectType.name}</span>
}

export default withStyles(styles)(ProjectType);