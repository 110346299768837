import React, {Component} from 'react';
import { connect } from 'react-redux'

import { projectTypeActions } from '../../../actions'


import ProjectTypeList from './projectTypeList/ProjectTypeList'

class ProjectTypeListContainer extends Component { 
	
	constructor(props) {
		super(props);

		this.state = {
			projectEditId: "",
			dialogEditOpen: false,
			dialogAddOpen: false,
		}
	}

	componentWillMount(){

		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch} = this.props	
		
		if( !nextProps.projectTypeState
			|| (!nextProps.projectTypeState.isFetching && !nextProps.projectTypeState.list )  ){
			dispatch( projectTypeActions.load() )			
		}
	}

	handleDialogEditOpen = (id) => {
		this.setState({
			projectEditId: id,
			dialogEditOpen: true,
		})
	}
	handleDialogEditClose = () => {

		this.setState({
			dialogEditOpen: false,
		})
	}

	handleDialogAddOpen = () => {
		this.setState({
			dialogAddOpen: true,
		})
	}
	handleDialogAddClose = () => {

		this.setState({
			dialogAddOpen: false,
		})
	}


	render(){
		const {projectTypeState} = this.props

		const {dialogEditOpen,projectEditId,dialogAddOpen } = this.state
		
		if( !projectTypeState  || !projectTypeState.list ) return false		

		const projectTypeList = projectTypeState.list
		
		return <ProjectTypeList projectTypeList={projectTypeList} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
	  	projectTypeState: state.projectType,   
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTypeListContainer);
