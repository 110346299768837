let apiUrl;
let domainUrl;

const hostname = window && window.location && window.location.hostname;

if(hostname === 'localhost') {
  	apiUrl = 'http://localhost:5002/'
  	domainUrl = 'http://localhost:3000/'
}else if( hostname === "127.0.0.1" ){
	apiUrl = 'http://127.0.0.1:5002/';
  	domainUrl = 'http://127.0.0.1:3000/';
}else {
  	apiUrl = 'https://x.hectormarti.com/track/';
  	domainUrl = 'https://track.hectormarti.com/';
}


export const API_URL =	apiUrl;
export const DOMAIN_URL =	domainUrl;