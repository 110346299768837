import React from 'react';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { Editor } from 'react-draft-wysiwyg';

import './TrackAdd.css';


const TrackAdd = (props) => {

	const {handleContentStateChange,descriptionState,handleChange,handleSubmit,handleClose} = props

	return <div>
		<DialogContent>
			<FormControl fullWidth >
				<TextField
	    			label="Title"
	    			name="title"
	    			id="margin-none"
	    			defaultValue=""
	    			helperText=""
	    			onChange={handleChange}
	  			/>
	  		</FormControl>

			<FormControl fullWidth >
				<Editor
					toolbarHidden="true"
	                initialContentState={descriptionState}					
					wrapperClassName="track-add-editor" 
					editorClassName="editor-class"
					onContentStateChange={handleContentStateChange}
	            />
			</FormControl>

			<FormControl fullWidth >
				<TextField
	    			label="Url"
	    			name="url"
	    			id="margin-none"
	    			defaultValue=""
	    			helperText=""
	    			onChange={handleChange}
	  			/>
	  		</FormControl>
		</DialogContent>
		<DialogActions>
			<Button onClick={handleClose} color="primary">
				Cancel
			</Button>
			<Button onClick={handleSubmit} color="primary">
				Add
			</Button>
		</DialogActions>	
 	</div>
}

export default TrackAdd