import { combineReducers } from 'redux'
import auth from './auth'
import alert from './alert'

import project from './project'
import projectType from './projectType'
import track from './track'
import param from './param'
import response from './response'
import paramResponse from './paramResponse'


const trackApp = combineReducers({
	alert,
	auth,
	project,
	projectType,
	track,
	param,
	response,
	paramResponse
})
 
export default trackApp