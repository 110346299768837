import {
  PROJECT_TYPE_LOAD_REQUEST,
  PROJECT_TYPE_LOAD_SUCCESS,
  PROJECT_TYPE_LOAD_FAILURE,
  PROJECT_TYPE_ADD_REQUEST,
  PROJECT_TYPE_ADD_SUCCESS,
  PROJECT_TYPE_ADD_FAILURE,
  PROJECT_TYPE_SAVE_REQUEST,
  PROJECT_TYPE_SAVE_SUCCESS,
  PROJECT_TYPE_SAVE_FAILURE,
  PROJECT_TYPE_DELETE_REQUEST,
  PROJECT_TYPE_DELETE_SUCCESS,
  PROJECT_TYPE_DELETE_FAILURE,
} from '../actions/projectType'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const projectType = (state = [], action) => {
	let newState = ""
	let rows = ""
	let elem = ""
	switch (action.type) {
		case PROJECT_TYPE_LOAD_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case PROJECT_TYPE_LOAD_SUCCESS:
			const projectList = {}
			action.rows.map((project) => {
				projectList[project.id] =	project
				return true;
			})
			
			newState = Object.assign( {}, state )
			if( action.rows ){				 
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: projectList},
				});
			}
			return newState 

		case PROJECT_TYPE_LOAD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case PROJECT_TYPE_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PROJECT_TYPE_ADD_SUCCESS:
			elem = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {
				  	[elem.id] : {$set: elem}
				},
			});
			return newState 

		case PROJECT_TYPE_ADD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case PROJECT_TYPE_SAVE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PROJECT_TYPE_SAVE_SUCCESS:
			elem = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {
					[elem.id]: {$set: elem}
				},
			})
			return newState 

		case PROJECT_TYPE_SAVE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case PROJECT_TYPE_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PROJECT_TYPE_DELETE_SUCCESS:
			elem = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {$unset: [elem.id]},
			});

			return newState 

		case PROJECT_TYPE_DELETE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		default:
			return state
  }
}

export default projectType