import React, {Component} from 'react';
import { connect } from 'react-redux'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import { projectActions } from '../../actions'


class ProjectDeleteContainer extends Component {

	handleClick = (event) => {
		const {dispatch} = this.props

		if(window.confirm('Are you sure you wish to delete this project?')){
        	dispatch( projectActions._delete(this.props.id))
        }

	}

	render(){	
		if( !this.props.id ) return false

		return <button type="button"
			className="btn btn-outline-danger" 
			onClick={this.handleClick}>
              <FontAwesomeIcon icon="trash-alt" size="lg"/>
      </button>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDeleteContainer);