
import React, {Component} from 'react';
import { connect } from 'react-redux'

import ProjectEdit from './projectTypeEdit/ProjectTypeEdit';

import { projectTypeActions } from '../../../actions'

import update from 'immutability-helper';


class ProjectTypeEditContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			projectType: "",
		}
	}

	componentWillMount( ){
		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch} = this.props


		this.setState({
			projectType: nextProps.projectType
		})
	}

	handleChange = (event) => {
		const {dispatch} = this.props

		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			projectType: { [name]: {$set: value} },
		});

		this.setState( newState );

		if(target.type === 'checkbox' ){
	        dispatch(projectTypeActions.saveField( this.state.projectType.id, name, value )) 
		}
	}

	handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

        dispatch(projectTypeActions.saveField( this.state.projectType.id, name, value )) 		
  	}

	
	
	
	render(){
		const {projectType,dialogTypeOpen} = this.state
		if( !projectType ) return false

		return <ProjectEdit 
			handleChange={this.handleChange}
			handleSelectChange={this.handleSelectChange}
			handleBlur={this.handleBlur}
			projectType={projectType}/>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  		auth: state.auth,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTypeEditContainer);
