import React, {Component} from 'react';
import classnames from 'classnames';

import {projectType} from '../../../../constants/';

import {  NavLink } from 'react-router-dom';

import ProjectTypeContainer from '../../../projectType/projectTypeContainer/ProjectTypeContainer'


import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'



import Favorite from '@material-ui/icons/Favorite';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const styles = theme => ({

});

class Project extends Component {    	
	
	render() {
		const {classes,project,handleDialogEditOpen}  = this.props
		if( !project) return false

		const sHidden = ( project.hidden )? <VisibilityOff /> : ""
		const sFavourite = ( project.favourite )? <Favorite /> : ""
		return <Card>
					<CardContent>
						<Typography className={classes.title} color="textSecondary" gutterBottom>
      						{sHidden} {sFavourite} <ProjectTypeContainer id={project.type_id} /> 
    					</Typography>
    					<Typography variant="h5" component="h2">
      						{project.name}
    					</Typography>					
					</CardContent>
					<CardActions>
						<NavLink to={"/t/"+ project.id}>
							<Button >View</Button>
						</NavLink>
						<Button onClick={(id) => handleDialogEditOpen(project.id)} >Edit</Button>
					</CardActions>
				</Card>
	}
}

export default withStyles(styles)(Project);