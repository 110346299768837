import React, {Component} from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

class ParamResponseAdd extends Component {    	
	render() {
		if( !this.props.param) return false
        
        const {param,handleParamChange} = this.props
       
        let paramElem = <TableRow>
            <TableCell>{param.param.name}</TableCell> 
            <TableCell>
                <input type="text" className="form-control" placeholder="Value"
                    name="value" param_id={param.param.id} value={param.value} 
                    onChange={handleParamChange} />
            </TableCell>
        </TableRow>

        
        return paramElem
	}
}

export default ParamResponseAdd;  