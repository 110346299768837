import React, {Component} from 'react';
import PageGeneralContainer from '../pageGeneralContainer/PageGeneralContainer';

import TrackMenuContainer from '../trackMenuContainer/TrackMenuContainer';
import TrackListContainer from '../track/trackListContainer/TrackListContainer';


class TrackPage extends Component {
	constructor(props) {
		super(props)

		this.state = {
			id: "",
		}
    }

	componentDidMount(){
		this.setState({
			id: this.props.match.params.id,
		})
	}

	componentWillReceiveProps(nextProps){
		this.setState({
			id: nextProps.match.params.id,
		})
	}

	render(){
		const menu = <TrackMenuContainer
			projectId={this.state.id} />
		const main = <TrackListContainer 
			projectId={this.state.id} />
		    
		return <PageGeneralContainer sidebar={menu} main={main} projectId={this.state.id} /> 
	}
}

export default TrackPage;