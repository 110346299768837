import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { projectTypeActions } from '../../../actions'

import ProjectTypeAdd from './projectTypeAdd/ProjectTypeAdd'

class ProjectTypeAddContainer extends Component { 
	constructor (props) { 		
		super(props);

		this.state = {
			projectType: this.emptyProjectType(),			
		}
	}	
	emptyProjectType(){
		let emptyProjectType = { 
			name: "",
		}
		return emptyProjectType;
	}

	setEmptyProjectType(){
		let newState = update( this.state, {
			projectType: {$set: this.emptyProjectType()}
		});		
		this.setState( newState );
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			projectType: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit = (event) => {
		const {dispatch,auth} = this.props

		dispatch( projectTypeActions.add( auth.user, this.state.projectType) )
     	this.setEmptyProjectType()
	}

	render(){
		return  <ProjectTypeAdd 
         	projectType={this.state.projectType}
			handleChange={this.handleChange}
			handleSubmit={this.handleSubmit} 
         	/>
  	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		auth: state.auth,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTypeAddContainer);
