import { paramService } from '../services';
import { alertActions } from './';


export const paramActions = {
    load,
    loadByProjectId,
    add,
    save,
    saveField,
    _delete,
}

export const PARAM_LOAD_REQUEST = 'PARAM_LOAD_REQUEST'
export const PARAM_LOAD_SUCCESS = 'PARAM_LOAD_SUCCESS'
export const PARAM_LOAD_FAILURE = 'PARAM_LOAD_FAILURE'

function load() {
    return dispatch => {
        dispatch(request( ));   
        paramService.load()
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: PARAM_LOAD_REQUEST } 
    }
    function success(rows) { return { type: PARAM_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: PARAM_LOAD_FAILURE, error } }
}

export const PARAM_LOAD_BY_PROJECT_ID_REQUEST = 'PARAM_LOAD_BY_PROJECT_ID_REQUEST'
export const PARAM_LOAD_BY_PROJECT_ID_SUCCESS = 'PARAM_LOAD_BY_PROJECT_ID_SUCCESS'
export const PARAM_LOAD_BY_PROJECT_ID_FAILURE = 'PARAM_LOAD_BY_PROJECT_ID_FAILURE'

function loadByProjectId(projectId) {
    return dispatch => {
        dispatch(request( projectId ));   
        paramService.loadByProjectId( projectId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( projectId ) { 
        return { type: PARAM_LOAD_BY_PROJECT_ID_REQUEST } 
    }
    function success(rows) { return { type: PARAM_LOAD_BY_PROJECT_ID_SUCCESS, rows } }
    function failure(error) { return { type: PARAM_LOAD_BY_PROJECT_ID_FAILURE, error } }
}

export const PARAM_ADD_REQUEST = 'PARAM_ADD_REQUEST'
export const PARAM_ADD_SUCCESS = 'PARAM_ADD_SUCCESS'
export const PARAM_ADD_FAILURE = 'PARAM_ADD_FAILURE'

function add( userId, project ) {
    return dispatch => {
        dispatch(request( userId, project ));   
        paramService.add(project)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( param ) { 
        return { type: PARAM_ADD_REQUEST, userId, param } 
    }
    function success(rows) { return { type: PARAM_ADD_SUCCESS, rows } }
    function failure(error) { return { type: PARAM_ADD_FAILURE, error } }
}

export const PARAM_SAVE_REQUEST = 'PARAM_SAVE_REQUEST'
export const PARAM_SAVE_SUCCESS = 'PARAM_SAVE_SUCCESS'
export const PARAM_SAVE_FAILURE = 'PARAM_SAVE_FAILURE'

function save( task ) {
    return dispatch => {
        dispatch(request( task ));   
        paramService.save(task)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: PARAM_SAVE_REQUEST, task } 
    }
    function success(rows) { return { type: PARAM_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: PARAM_SAVE_FAILURE, error } }
}

export const PARAM_SAVE_FIELD_REQUEST = 'PARAM_SAVE_FIELD__REQUEST'
export const PARAM_SAVE_FIELD__SUCCESS = 'PARAM_SAVE_FIELD__SUCCESS'
export const PARAM_SAVE_FIELD__FAILURE = 'PARAM_SAVE_FIELD__FAILURE'

function saveField( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        paramService.saveField(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: PARAM_SAVE_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: PARAM_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: PARAM_SAVE_FAILURE, error } }
}

export const PARAM_DELETE_REQUEST = 'PARAM_DELETE_REQUEST'
export const PARAM_DELETE_SUCCESS = 'PARAM_DELETE_SUCCESS'
export const PARAM_DELETE_FAILURE = 'PARAM_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        paramService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: PARAM_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: PARAM_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: PARAM_DELETE_FAILURE, error } }
}