import React, {Component} from 'react';
import { connect } from 'react-redux'

import IconButton from '@material-ui/core/IconButton';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import { responseActions } from '../../actions'


class ResponseDeleteContainer extends Component {

	handleClick = (event) => {
		const {dispatch} = this.props

		if(window.confirm('Are you sure you wish to delete this response?')){
        	dispatch( responseActions._delete(this.props.id))
        }

	}

	render(){	
		if( !this.props.id ) return false

		return <IconButton onClick={this.handleClick} >
			<FontAwesomeIcon icon="trash-alt" />
		</IconButton>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseDeleteContainer);