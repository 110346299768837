import {fetchApi} from '../helpers';

export const trackService = {
	load,
    loadByProjectId,
    loadByProjectIdFilter,
	add,
    saveField,
    encrypt,
    _delete,
};

function load() {
    const res  = fetchApi('api/trackLoad', {
    }) 

    return res
}

function loadByProjectId( projectId ) {
    const res  = fetchApi('api/trackLoadByProjectId', {
        projectId: projectId
    }) 

    return res
}

function loadByProjectIdFilter( projectId, filter ) {
    const res  = fetchApi('api/trackLoadByProjectIdFilter', {
        projectId: projectId,
        filter: filter
    }) 

    return res
}

function add( track ) {
    const res  = fetchApi('api/trackAdd', {
        track: track,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/trackSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function encrypt(id,password) {
    const res  = fetchApi('api/trackEncrypt', {
        id: id,
        password: password,
    }) 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/trackDelete', {
        id: id,
    }) 

    return res
}