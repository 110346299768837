import React from 'react';

import { withStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid';


import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {  NavLink } from 'react-router-dom';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import IconButton from '@material-ui/core/IconButton';

import Fab from '@material-ui/core/Fab';

import ProjectContainer from '../../projectContainer/ProjectContainer'

import ProjectAddContainer from '../../projectAddContainer/ProjectAddContainer'
import ProjectEditContainer from '../../projectEditContainer/ProjectEditContainer'
import ProjectDeleteContainer from '../../ProjectDeleteContainer'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = theme => ({
	root: {
		marginTop: theme.spacing(4)
	},
  	title: {
    	fontSize: 14,
  	},
  	pos: {
    	marginBottom: 12,
  	},
  	fab: {
        position: 'fixed',
        top: theme.spacing(10),
        right: theme.spacing(3),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
});

const ProjectList = (props) => {
	const {projectlist,classes,
		dialogEditOpen,projectEditId,handleDialogEditOpen,handleDialogEditClose,
		dialogAddOpen,handleDialogAddOpen,handleDialogAddClose} = props

	if( !projectlist ) return false

	return <div className={classes.root}>
		<Grid container className={classes.container} justify="center" spacing={4}>		
		{Object.keys(projectlist).map( (i) => {
			let p = projectlist[i]
			return <Grid item xs={12} sm={8} md={4} className={classes.side} key={"grid"+ p.id}>
                <ProjectContainer project={p} handleDialogEditOpen={handleDialogEditOpen} />
			</Grid>
		})
		}
		</Grid>
		
		<Fab color="primary"
            className={classes.fab}
            onClick={handleDialogAddOpen}>
            <FontAwesomeIcon icon="plus" />
        </Fab> 

		<Dialog
            open={dialogEditOpen}
            onClose={handleDialogEditClose}
            scroll="body"
            maxWidth="md">
            <DialogContent>
                <ProjectEditContainer 
                    id={projectEditId} 
                    handleClose={handleDialogEditClose} />
            </DialogContent>   
            <DialogActions>
            	<ProjectDeleteContainer id={projectEditId} />
            </DialogActions>             
        </Dialog>

        <Dialog
            open={dialogAddOpen}
            onClose={handleDialogAddClose}
            scroll="body"
            maxWidth="md">
            <DialogTitle id="simple-dialog-title">Add Project</DialogTitle>  
            <DialogActions>
            	<ProjectAddContainer  />
            </DialogActions>             
        </Dialog>
	</div>
}

export default withStyles(styles)(ProjectList);