import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { paramActions } from '../../actions'
import ParamAdd from './paramAdd/ParamAdd'

class ParamAddContainer extends Component { 
	constructor(props) {
		super(props)

		this.state = {
			param: this.emptyParam(),			
		}
	}
	
	emptyParam(){
		let emptyParam = { 
			track_id: this.props.trackId,
			type: "",
			name: "",
			value: ""
		}
		return emptyParam;
	}

	setEmptyParam(){
		let newState = update( this.state, {
			param: {$set: this.emptyParam()}
		});		
		this.setState( newState );
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			param: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit = (event) => {
		const {dispatch,auth} = this.props

		dispatch( paramActions.add( auth.user, this.state.param) )
      	this.setEmptyParam()
	}

	render(){

		return  <ParamAdd 
         	param={this.state.param}
			handleChange={this.handleChange}
			handleSubmit={this.handleSubmit} 
         	/>
	}
}

const mapStateToProps = (state, ownProps) => {
  return {
	  param: state.param,  
	  auth: state.auth,     
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)( ParamAddContainer );
