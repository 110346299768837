import React, {Component} from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

class ParamResponse extends Component {    	
	render() {
		if( !this.props.paramResponse) return false
        const {paramResponse,handleChange,handleBlur,editAction} = this.props
       
        let responseValue = ""

        if( paramResponse.response && paramResponse.response.value ){
            responseValue = paramResponse.response.value
        }

        let paramElem = ""
        if( editAction ){
            paramElem = <TableRow>
                <TableCell>{paramResponse.name}</TableCell> 
                <TableCell>
                    <input type="text" className="form-control" placeholder="Value"
                        name="value" value={responseValue} 
                        onChange={handleChange} onBlur={handleBlur} />
                </TableCell>
            </TableRow>
        }
        else{
            paramElem = <TableRow>
                <TableCell>{paramResponse.name}</TableCell> 
                <TableCell>{responseValue}</TableCell>
            </TableRow>

        }
        
        return paramElem
	}
}

export default ParamResponse;  