import React, {Component} from 'react';
import { connect } from 'react-redux'



import {sortProjectList} from '../../../functions/';


import ProjectList from './projectList/ProjectList'

class ProjectListContainer extends Component { 
	
	constructor(props) {
		super(props);

		this.state = {
			projectEditId: "",
			dialogEditOpen: false,
			dialogAddOpen: false,
		}
	}

	componentWillMount(){

		this.setState({})
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch} = this.props	
		if( !nextProps.projectId) return false
		
		/*if( !nextProps.track.isFetching 
			|| !nextProps.track.list[nextProps.projectId] ) ){
			
		}*/

		this.setState({
			projectId: nextProps.projectId
		})
	}

	handleDialogEditOpen = (id) => {
		this.setState({
			projectEditId: id,
			dialogEditOpen: true,
		})
	}
	handleDialogEditClose = () => {

		this.setState({
			dialogEditOpen: false,
		})
	}

	handleDialogAddOpen = () => {
		this.setState({
			dialogAddOpen: true,
		})
	}
	handleDialogAddClose = () => {

		this.setState({
			dialogAddOpen: false,
		})
	}


	render(){
		const {project,projectType} = this.props
		const {dialogEditOpen,projectEditId,
			dialogAddOpen } = this.state
		
		if( !project.list ) return false		

		let  projectList = sortProjectList( project.list )
		
		if(projectType){
			projectList = projectList.filter( (p) => (projectType === p.type_id ))
		}

		return <ProjectList 
			dialogEditOpen = {dialogEditOpen}
			projectEditId = {projectEditId}
			handleDialogEditOpen={this.handleDialogEditOpen}
			handleDialogEditClose={this.handleDialogEditClose}

			dialogAddOpen = {dialogAddOpen}
			handleDialogAddOpen={this.handleDialogAddOpen}
			handleDialogAddClose={this.handleDialogAddClose}

			projectlist={projectList} />	    
	
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
	  	project: state.project,   
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectListContainer);
