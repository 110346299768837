import React, {Component} from 'react';
import { connect } from 'react-redux'

import { EditorState } from 'draft-js';

import update from 'immutability-helper';

import { trackActions } from '../../../actions'

import Track from './track/Track'

var CryptoJS = require("crypto-js");

class TrackContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			responseAddOpen: false,
			track: "",
			paramList: "",
			responseList: "",
			openDecrypt: false,
			decrypt: false,
			password: "",
			descriptionState: EditorState.createEmpty(),
		}
	}


  	handleResponseAddOpen = () => {
    	this.setState({ responseAddOpen: true });
  	};

  	handleResponseAddClose = () => {
    	this.setState({ responseAddOpen: false });
  	};


  	componentWillMount(){
  		this.componentWillReceiveProps(this.props)
  	}
	componentWillReceiveProps( nextProps ){
		let	paramList = {}
		if( nextProps.param.list && nextProps.param.list[nextProps.track.id]  ){
			paramList = nextProps.param.list[nextProps.track.id]
		}
		let	responseList = {}
		if( nextProps.response.list && nextProps.response.list[nextProps.track.id]  ){
			responseList = nextProps.response.list[nextProps.track.id]
		}

		let responseHtml = ""
		if( !nextProps.track.encrypted || this.state.decrypt ){
			try {
				responseHtml = JSON.parse(nextProps.track.description)
			} catch (e) {
				nextProps.track.description = JSON.stringify(EditorState.createEmpty())
				responseHtml = EditorState.createEmpty()
			}
		}

		let responseListSort = Object.keys(responseList)
			.map( i => responseList[i])
			.sort((a, b) => a.date < b.date)

		this.setState({
			editAction: false,
			track: nextProps.track,
			descriptionState: responseHtml,
			paramList : paramList,
			responseList: responseListSort,
		})
	}

    handleChange = (event) => {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			track: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleContentStateChange: Function = (contentState) => {
		let newState = update( this.state, {
			descriptionState: {$set: contentState },
			track: { description: {$set: JSON.stringify(contentState) } },
		})

		this.setState( newState );
  	}

  	handleContentStateBlur: Function = (event) => {
		const {dispatch} = this.props
		let description = this.state.track.description
		if( this.state.track.encrypted === 1){
			if( this.state.password ){
				let encryptedPassword =	CryptoJS.AES.encrypt(description, this.state.password);
				dispatch(trackActions.saveField( this.state.track.id, "description", encryptedPassword.toString()) )
			}
		}else{
        	dispatch(trackActions.saveField( this.state.track.id, "description", description) )
        }
  	}

	handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

        dispatch(trackActions.saveField( this.state.track.id, name, value )) 		
  	}

  	handleClickDecrypt = () => {
		this.setState({
			openDecrypt: true
		})
	}
	handleCloseDecrypt = () => {
		this.setState({
			openDecrypt: false
		})
	}

	handleDecrypt = () => {
		var bytes  = CryptoJS.AES.decrypt(this.state.track.description, this.state.password);
		var plaintext = bytes.toString(CryptoJS.enc.Utf8);
		
		let newState = update( this.state, {
			openDecrypt: {$set: false},
			decrypt: {$set: true},
			descriptionState: {$set: JSON.parse(plaintext)},
			track: { description: {$set: JSON.parse(plaintext)} }
		});

		this.setState( newState )
	}

	handleChangePassword = (event) => {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;

		let newState = update( this.state, {
			password: {$set: value},
		});

		this.setState( newState );
	}


	render(){
		if( ! this.props.track ) return false

		return <Track 
			track={this.state.track} 
			paramList={this.state.paramList}
			responseList={this.state.responseList}
			
			responseAddOpen={this.state.responseAddOpen}			
			handleResponseAddOpen={this.handleResponseAddOpen}
			handleResponseAddClose={this.handleResponseAddClose}

			handleChange={this.handleChange}
			handleContentStateChange={this.handleContentStateChange}
			handleContentStateBlur={this.handleContentStateBlur}
			descriptionState={this.state.descriptionState}
			handleBlur={this.handleBlur}
			
			openDecrypt={this.state.openDecrypt}
			handleClickDecrypt={this.handleClickDecrypt}
			handleCloseDecrypt={this.handleCloseDecrypt}
			handleChangePassword={this.handleChangePassword}
			password={this.password}
			decrypt={this.state.decrypt}
			handleDecrypt={this.handleDecrypt}
			/>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		param: state.param,  
		response: state.response,
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackContainer);
