import React from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	root: {
	},	
});

const ProjectTypeAdd = (props) => {
	const {projectType,handleChange,handleSubmit,classes} = props

	return <tr className={classes.root}>
		<td>
			<TextField
				label="Name"
		    	className={classes.textField}
		    	name="name"
		    	placeholder="Name" 
			    onChange={handleChange} 
			    value={projectType.name} />
		</td>
		<td>
			<button className="btn btn-outline-secondary" type="button" 
				onClick={handleSubmit}>Add Type</button>
		</td>
	</tr>
}

export default withStyles(styles)(ProjectTypeAdd);