import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {  NavLink } from 'react-router-dom';

import './TrackMenu.css'

const TrackMenu = (props) => {
	const {projectList} = props
	if( !projectList ) return false

	return (
		<List>
			{Object.keys(projectList).map( (i) => {
				const project = projectList[i]
				return <NavLink to={"/t/"+ project.id}>
					<ListItem button>
      					<ListItemText primary={project.name} />
    				</ListItem>
    			</NavLink>
			})}
		</List>
	)
}

export default TrackMenu;