import React from 'react';
import { Editor } from 'react-draft-wysiwyg';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import ParamResponseAdd from '../../../paramResponseContainer/paramResponseAdd/ParamResponseAdd';


const ResponseAdd = (props) => {

	const {handleContentStateChange, handleSubmit, handleChange,handleParamChange, handleClose, paramResponse} = props

	var paramListRowTable = ""
	if( paramResponse ){
		paramListRowTable =	paramResponse.map( (param) => {
			return <ParamResponseAdd key={param.param.id} 
				param={param}
				handleParamChange={handleParamChange}
			/>
		})
	}

	return <div>
		<DialogContent>
			<FormControl fullWidth >
				<TextField
	    			label="Title"
	    			name="title"
	    			id="margin-none"
	    			defaultValue=""
	    			helperText=""
	    			onChange={handleChange}
	  			/>
	  		</FormControl>
	  		<FormControl fullWidth >
 				<Editor
					toolbarHidden="true"
					wrapperClassName="wrapper-class form-control" 
					editorClassName="editor-class"
					toolbarClassName="toolbar-class"
					onContentStateChange={handleContentStateChange}
                />
            </FormControl>
            <Table className="w-100">
                <TableBody>
                {paramListRowTable}
                </TableBody>
            </Table>   

            <DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button onClick={handleSubmit} color="primary">
					Add
				</Button>
			</DialogActions>
		</DialogContent>
 	</div>
}

export default ResponseAdd;  