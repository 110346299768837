import {fetchApi} from '../helpers';

export const projectTypeService = {
	load,
	add,
    saveField,
    _delete,
};

function load() {
    const res  = fetchApi('api/projectTypeLoad', {
    }) 

    return res
}

function add( projectType ) {
    const res  = fetchApi('api/projectTypeAdd', {
        projectType: projectType,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/projectTypeSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/projectTypeDelete', {
        id: id,
    }) 

    return res
}