import { responseService } from '../services';
import { alertActions } from './';


export const responseActions = {
    load,
    loadByProjectId,
    add,
    save,
    saveField,
    _delete,
}

export const RESPONSE_LOAD_REQUEST = 'RESPONSE_LOAD_REQUEST'
export const RESPONSE_LOAD_SUCCESS = 'RESPONSE_LOAD_SUCCESS'
export const RESPONSE_LOAD_FAILURE = 'RESPONSE_LOAD_FAILURE'

function load() {
    return dispatch => {
        dispatch(request( ));   
        responseService.load()
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( ) { 
        return { type: RESPONSE_LOAD_REQUEST } 
    }
    function success(rows) { return { type: RESPONSE_LOAD_SUCCESS, rows } }
    function failure(error) { return { type: RESPONSE_LOAD_FAILURE, error } }
}

export const RESPONSE_LOAD_BY_PROJECT_ID_REQUEST = 'RESPONSE_LOAD_BY_PROJECT_ID_REQUEST'
export const RESPONSE_LOAD_BY_PROJECT_ID_SUCCESS = 'RESPONSE_LOAD_BY_PROJECT_ID_SUCCESS'
export const RESPONSE_LOAD_BY_PROJECT_ID_FAILURE = 'RESPONSE_LOAD_BY_PROJECT_ID_FAILURE'

function loadByProjectId(projectId) {
    return dispatch => {
        dispatch(request( projectId ));   
        responseService.loadByProjectId( projectId )
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( projectId ) { 
        return { type: RESPONSE_LOAD_BY_PROJECT_ID_REQUEST } 
    }
    function success(rows) { return { type: RESPONSE_LOAD_BY_PROJECT_ID_SUCCESS, rows } }
    function failure(error) { return { type: RESPONSE_LOAD_BY_PROJECT_ID_FAILURE, error } }
}

export const RESPONSE_ADD_REQUEST = 'RESPONSE_ADD_REQUEST'
export const RESPONSE_ADD_SUCCESS = 'RESPONSE_ADD_SUCCESS'
export const RESPONSE_ADD_FAILURE = 'RESPONSE_ADD_FAILURE'

function add( userId, resp ) {
    return dispatch => {
        dispatch(request( userId, resp ));   
        responseService.add(resp)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( response ) { 
        return { type: RESPONSE_ADD_REQUEST, userId, response } 
    }
    function success(rows) { return { type: RESPONSE_ADD_SUCCESS, rows } }
    function failure(error) { return { type: RESPONSE_ADD_FAILURE, error } }
}

export const RESPONSE_SAVE_REQUEST = 'RESPONSE_SAVE_REQUEST'
export const RESPONSE_SAVE_SUCCESS = 'RESPONSE_SAVE_SUCCESS'
export const RESPONSE_SAVE_FAILURE = 'RESPONSE_SAVE_FAILURE'

function save( task ) {
    return dispatch => {
        dispatch(request( task ));   
        responseService.save(task)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: RESPONSE_SAVE_REQUEST, task } 
    }
    function success(rows) { return { type: RESPONSE_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: RESPONSE_SAVE_FAILURE, error } }
}

export const RESPONSE_SAVE_FIELD_REQUEST = 'RESPONSE_SAVE_FIELD__REQUEST'
export const RESPONSE_SAVE_FIELD__SUCCESS = 'RESPONSE_SAVE_FIELD__SUCCESS'
export const RESPONSE_SAVE_FIELD__FAILURE = 'RESPONSE_SAVE_FIELD__FAILURE'

function saveField( id, name, value ) {
    return dispatch => {
        dispatch(request( id, name, value ));   
        responseService.saveField(id, name, value)
            .then(
                response => { 
                    if(response.rows){
                        dispatch(success(response.rows));
                    }
                    else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request( task ) { 
        return { type: RESPONSE_SAVE_REQUEST, id, name, value } 
    }
    function success(rows) { return { type: RESPONSE_SAVE_SUCCESS, rows } }
    function failure(error) { return { type: RESPONSE_SAVE_FAILURE, error } }
}

export const RESPONSE_DELETE_REQUEST = 'RESPONSE_DELETE_REQUEST'
export const RESPONSE_DELETE_SUCCESS = 'RESPONSE_DELETE_SUCCESS'
export const RESPONSE_DELETE_FAILURE = 'RESPONSE_DELETE_FAILURE'

function _delete( id ) { 
    return dispatch => {
        dispatch(request( id ));   
        responseService._delete( id )
            .then(
                response => { 
                    dispatch(success(response.rows));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(id) { 
        return { type: RESPONSE_DELETE_REQUEST, id: id } 
    }
    function success(rows) { return { type: RESPONSE_DELETE_SUCCESS, rows } }
    function failure(error) { return { type: RESPONSE_DELETE_FAILURE, error } }
}