import React, {Component} from 'react';
import classnames from 'classnames';

import {projectType} from '../../../../constants/';


import ProjectTypeListContainer from '../../../projectType/projectTypeListContainer/ProjectTypeListContainer'



import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

import Favorite from '@material-ui/icons/Favorite';

import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import {Visibility, VisibilityOff} from '@material-ui/icons/';

const styles = theme => ({
  	container: {
   		margin: theme.spacing(0),
  	},
  	textField: {
    	margin: theme.spacing(2),
  	},
  	formControl: {
   		margin: theme.spacing(2),
   		minWidth: 120,
  	},
});

class ProjectEdit extends Component {    	
	
	render() {
		if( !this.props.project) return false
		const {project, projectType, classes,
			handleChange, handleBlur,
			dialogTypeOpen, handleDialogTypeClose, handleDialogTypeOpen } = this.props

		return <div className={classnames(classes.container)}>

    		<TextField
    			label="Name"
    			className={classes.textField}
    			name="name"
    			placeholder="Name" 
	        	onChange={handleChange} 
	         onBlur={handleBlur} 
	         value={project.name} />

			<FormControl className={classes.formControl}>
          		<InputLabel htmlFor="project-type">Type</InputLabel>
          		<Select
            		value={project.type_id}
            		onChange={handleChange}
            		onBlur={handleBlur} 
            		inputProps={{
            	  		name: 'type_id',
              			id: 'project-type',
            		}}>

            		<MenuItem value="">
              			<em>None</em>
            		</MenuItem>
            		{Object.keys(projectType).map( (i) => {
    						let type = projectType[i]
    						return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>
    					})}            	
          		</Select>
              <Button onClick={() => handleDialogTypeOpen()}>Edit</Button>
        	</FormControl>
        	
        	<br/>

  			<TextField
    			label="Url"
    			className={classes.textField}
    			name="url"
    			placeholder="Url" 
				onChange={handleChange} 
				onBlur={handleBlur} 
				value={project.url} />

			<TextField
    			label="Position"
    			className={classes.textField}
    			name="position"
    			placeholder="Position" 
				onChange={handleChange} 
				onBlur={handleBlur} 
				value={project.position} />
			<br/>

			<FormControl className={classes.formControl}>				  
        		<FormControlLabel
        			control={<Checkbox 
        				icon={<FavoriteBorder />} checkedIcon={<Favorite />}
        				name="favourite" checked={project.favourite} onChange={handleChange}  />}
        			label="Favourite"
      			/>  	
        	</FormControl>
        	<FormControl className={classes.formControl}> 
        		<FormControlLabel
        			control={<Checkbox 
        				icon={<Visibility />} checkedIcon={<VisibilityOff />}
        				name="hidden" checked={project.hidden} onChange={handleChange}  />}
        			label="Hidden"
      			/>  	
        	</FormControl>   

         <Dialog
            open={dialogTypeOpen}
            onClose={handleDialogTypeClose}
            scroll="body"
            maxWidth="md">
            <DialogTitle id="simple-dialog-title">Types</DialogTitle>  
            <DialogActions>
              <ProjectTypeListContainer  />
            </DialogActions>             
        	</Dialog>     	
      </div>
	}
}

export default withStyles(styles)(ProjectEdit);