import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { trackActions } from '../../../actions'
import TrackAdd from './trackAdd/TrackAdd'

class TrackAddContainer extends Component { 
	constructor(props) {
		super(props)

		this.state = {
			track: this.emptyTrack(),			
		}
	}
	
	emptyTrack(){
		let emptyTrack = { 
			project_id: this.props.projectId,
			date: new Date(),
			url: "",
			title: "",
			descriptionState: "",
		}
		return emptyTrack;
	}

	setEmptyTrack(){
		let newState = update( this.state, {
			track: {$set: this.emptyTrack()}
		});		
		this.setState( newState );
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			track: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleContentStateChange: Function = (contentState) => {
		let newState = update( this.state, {
			descriptionState: {$set: contentState },
			track: { description: {$set: JSON.stringify(contentState) } },
		})

		this.setState( newState );
  	}

	handleSubmit = (event) => {
		const {dispatch,auth} = this.props

		dispatch( trackActions.add( auth.user, this.state.track) )
      	this.setEmptyTrack()
      	this.props.handleClose()
	}

	render(){

		return  <TrackAdd 
         	track={this.state.track}
			handleChange={this.handleChange}
			descriptionState={this.state.descriptionState}			
			handleContentStateChange={this.handleContentStateChange}			
			handleSubmit={this.handleSubmit} 
			handleClose={this.props.handleClose} 
        />
	}
}

const mapStateToProps = (state, ownProps) => {
  return {
	  task: state.task,  
	  auth: state.auth,     
  };
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackAddContainer);
