export function sortProjectList(obj) {

	function checkPosition(a,b){
			if( a.position === b.position ){
				return 0
			}else if( a.position > b.position ){
				return 1
			}else if( a.position < b.position ){
				return -1
			}
		}

	if( ! obj ) return []
	const projectList = [].concat(Object.keys(obj)).sort( (ia,ib) => {
		let a = obj[ia]
		let b = obj[ib]
		if( a.hidden === b.hidden && a.favourite === b.favourite){
			return checkPosition(a,b)
		}else{
			if( a.favourite > b.favourite ){
				return -1
			}else if( a.favourite < b.favourite ){
				return 1
			}
			if( a.hidden > b.hidden ){
				return 1
			}else if( a.hidden < b.hidden ){
				return -1
			}
		}
	}).map( (i) => { return obj[i] } )

	return projectList
}