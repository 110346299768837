export const paramType = { 
	number:{
		value: 'number',
		label: 'Number'
	},
	duration:{
		value: 'duration',
		label: 'Duration'
	},
	seo_position:{
		value: 'seo_position',
		label: 'Seo Position',
	},
	seo_traffic:{
		value: 'seo_traffic',
		label: 'Seo Traffic',
	},
	
}