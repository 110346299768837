
import React, {Component} from 'react';
import { connect } from 'react-redux'

import ProjectEdit from './projectEdit/ProjectEdit';

import { projectActions, projectTypeActions } from '../../../actions'

import update from 'immutability-helper';


class ProjectEditContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			project: "",
			projectType: [],
			dialogTypeOpen: false
		}
	}

	handleChange = (event) => {
		const {dispatch} = this.props

		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			project: { [name]: {$set: value} },
		});

		this.setState( newState );

		if(target.type === 'checkbox'){
	        dispatch(projectActions.saveField( this.state.project.id, name, value )) 
		}
	}

	handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

        dispatch(projectActions.saveField( this.state.project.id, name, value )) 		
  	}

	componentWillMount( ){
		const {dispatch} = this.props
		dispatch( projectActions.load() )
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch} = this.props

		if(!nextProps.project.list){
			dispatch( projectActions.load() )
		}
		else{
			this.setState({
				project: nextProps.project.list[nextProps.id]
			})
		}

		if(!nextProps.projectTypeState.list){
			dispatch( projectTypeActions.load() )
		}
		else{
			this.setState({
				projectType: nextProps.projectTypeState.list
			})
		}
	}
	
	handleDialogTypeOpen = () => {
		
		this.setState({
			dialogTypeOpen: true,
		})
	}
	handleDialogTypeClose = () => {

		this.setState({
			dialogTypeOpen: false,
		})
	}

	render(){
		const {project,dialogTypeOpen,projectType} = this.state
		if( !project ) return false


		return <ProjectEdit 
			handleChange={this.handleChange}
			handleSelectChange={this.handleSelectChange}
			handleBlur={this.handleBlur}
			dialogTypeOpen={dialogTypeOpen}
			handleDialogTypeOpen={this.handleDialogTypeOpen}
			handleDialogTypeClose={this.handleDialogTypeClose}
			project={project}
			projectType={projectType} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  		auth: state.auth,
		project: state.project,    
		projectTypeState: state.projectType,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectEditContainer);
