import React from 'react';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import {paramType} from '../../../constants';

import './ParamAdd.css';

const ParamAdd = (props) => {

	const {param,handleChange,handleSubmit} = props

	return <TableRow>
		<TableCell>
			<FormControl>
				<InputLabel htmlFor="param-type-add">Type</InputLabel>
				<Select
	                inputProps={{
	                    name: 'type',
	                    id: 'param-type-add'
	                }}
	                onChange={handleChange}
	                value={param.type}>
	                <MenuItem value=""><em>None</em></MenuItem>
	                {Object.keys(paramType).map( (i) => <MenuItem key={i} value={i}>{paramType[i].label}</MenuItem>)}
	            </Select>
	        </FormControl>
		</TableCell>
		<TableCell>
			<TextField
                label="Name"
                name="name"
                onChange={handleChange}
            />
		</TableCell>
		<TableCell>
			<TextField
	            label="Value"
	            name="value"
	            onChange={handleChange}
	            value={param.value} 
	        />
		</TableCell>
		<TableCell>
			<button className="btn btn-outline-secondary" type="button" 
   			onClick={handleSubmit}>Add</button>
   		</TableCell>
 	</TableRow>
}

export default ParamAdd;  