
import React, {Component} from 'react';
import { connect } from 'react-redux'

import Project from './project/Project';

import { projectActions } from '../../../actions'

import update from 'immutability-helper';


class ProjectContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			project: props.project	
		}
	}
	
	componentWillReceiveProps( nextProps ){
		const {dispatch} = this.props	
		if( !nextProps.project) return false
		
		/*if( !nextProps.track.isFetching 
			|| !nextProps.track.list[nextProps.projectId] ) ){
			
		}*/

		this.setState({
			project: nextProps.project
		})
	}

	render(){
		const {project} = this.state
		const {handleDialogEditOpen} = this.props
		
		if( !project ) return false


		return <Project  
			project={project}
			handleDialogEditOpen = {handleDialogEditOpen}/>
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  		auth: state.auth,
		stateProject: state.project,     
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer);