import {
  	PARAM_RESPONSE_LOAD_REQUEST,
	PARAM_RESPONSE_LOAD_SUCCESS,
	PARAM_RESPONSE_LOAD_FAILURE,
	PARAM_RESPONSE_LOAD_BY_PROJECT_ID_REQUEST,
	PARAM_RESPONSE_LOAD_BY_PROJECT_ID_SUCCESS,
	PARAM_RESPONSE_LOAD_BY_PROJECT_ID_FAILURE,
	PARAM_RESPONSE_ADD_REQUEST,
	PARAM_RESPONSE_ADD_SUCCESS,
	PARAM_RESPONSE_ADD_FAILURE,
	PARAM_RESPONSE_SAVE_REQUEST,
	PARAM_RESPONSE_SAVE_SUCCESS,
	PARAM_RESPONSE_SAVE_FAILURE,
	PARAM_RESPONSE_SAVE_VALUE_REQUEST,
	PARAM_RESPONSE_SAVE_VALUE_SUCCESS,
	PARAM_RESPONSE_SAVE_VALUE_FAILURE,
	PARAM_RESPONSE_DELETE_REQUEST,
	PARAM_RESPONSE_DELETE_SUCCESS,
	PARAM_RESPONSE_DELETE_FAILURE,
} from '../actions'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const paramResponse = (state = [], action) => {
	let newState = ""
	let rows = ""
	switch (action.type) {
		case PARAM_RESPONSE_LOAD_REQUEST:
		case PARAM_RESPONSE_LOAD_BY_PROJECT_ID_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case PARAM_RESPONSE_LOAD_SUCCESS:
		case PARAM_RESPONSE_LOAD_BY_PROJECT_ID_SUCCESS:
			const paramResponseList = {}
			action.rows.map((paramResponse) => {
				paramResponseList[paramResponse.track_id] = paramResponseList[paramResponse.track_id] || {}
				paramResponseList[paramResponse.track_id][paramResponse.id] =	paramResponse
				return true;
			})

			newState = Object.assign( {}, state )
			if( action.rows ){				 				
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: paramResponseList},
				});
			}
			return newState 

		case PARAM_RESPONSE_LOAD_FAILURE:
		case PARAM_RESPONSE_LOAD_BY_PROJECT_ID_FAILURE:
			return {};

		case PARAM_RESPONSE_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PARAM_RESPONSE_ADD_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {
					  	[row.id] : {$set: row}
					},
				});
			}
			return newState 

		case PARAM_RESPONSE_ADD_FAILURE:
			return {};

		case PARAM_RESPONSE_SAVE_REQUEST:
		case PARAM_RESPONSE_SAVE_VALUE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PARAM_RESPONSE_SAVE_SUCCESS:
		case PARAM_RESPONSE_SAVE_VALUE_SUCCESS:		
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {
				  	[rows.track_id] :{
					 	[rows.id]: {$set: rows}
				  	}
				},
			})
			return newState 

		case PARAM_RESPONSE_SAVE_FAILURE:
		case PARAM_RESPONSE_SAVE_VALUE_FAILURE:
			return {};



		case PARAM_RESPONSE_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PARAM_RESPONSE_DELETE_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {$uset: row.id},
				});
			}
			return newState 

		case PARAM_RESPONSE_DELETE_FAILURE:
			return {};

		default:
			return state
  }
}

export default paramResponse