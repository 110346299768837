import {
  TRACK_LOAD_REQUEST,
  TRACK_LOAD_SUCCESS,
  TRACK_LOAD_FAILURE,
  TRACK_LOAD_BY_PROJECT_ID_REQUEST,
  TRACK_LOAD_BY_PROJECT_ID_SUCCESS,
  TRACK_LOAD_BY_PROJECT_ID_FAILURE,
  TRACK_FILTER_REQUEST,
  TRACK_FILTER_SUCCESS,
  TRACK_FILTER_FAILURE,
  TRACK_ADD_REQUEST,
  TRACK_ADD_SUCCESS,
  TRACK_ADD_FAILURE,
  TRACK_SAVE_REQUEST,
  TRACK_SAVE_SUCCESS,
  TRACK_SAVE_FAILURE,
  TRACK_DELETE_REQUEST,
  TRACK_DELETE_SUCCESS,
  TRACK_DELETE_FAILURE,
} from '../actions/track'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const track = (state = [], action) => {
	let newState = ""
	let rows = ""
	switch (action.type) {
		case TRACK_LOAD_REQUEST:
		case TRACK_FILTER_REQUEST:
		case TRACK_LOAD_BY_PROJECT_ID_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case TRACK_LOAD_SUCCESS:
		case TRACK_FILTER_SUCCESS:
		case TRACK_LOAD_BY_PROJECT_ID_SUCCESS:
			let trackList = {}
			action.rows.map((track) => {
				trackList[track.project_id] = trackList[track.project_id] || {}
				trackList[track.project_id][track.id] =	track
				return true;
			})

			newState = Object.assign( {}, state )
			if( action.rows ){				 				
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: trackList},
				});
			}
			return newState 

		case TRACK_LOAD_FAILURE:
		case TRACK_FILTER_FAILURE:
		case TRACK_LOAD_BY_PROJECT_ID_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TRACK_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TRACK_ADD_SUCCESS:
			rows = Object.assign({},action.rows)

			let trackListAdd = state.list
			trackListAdd[rows.project_id] = trackListAdd[rows.project_id]|| {}
			trackListAdd[rows.project_id][rows.id] = rows

			newState = update( state, {
				isSaving: {$set: false},
				list: {$set: trackListAdd}
			})
			return newState 

		case TRACK_ADD_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TRACK_SAVE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TRACK_SAVE_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {
				  	[rows.project_id] :{
					 	[rows.id]: {$set: rows}
				  	}
				},
			})
			return newState 

		case TRACK_SAVE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		case TRACK_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case TRACK_DELETE_SUCCESS:
			rows = Object.assign({},action.rows)

			newState = update( state, {
				isSaving: {$set: false},
				list: {
					[rows.project_id]: {$unset: [rows.id]}
				}
			})
			return newState 

		case TRACK_DELETE_FAILURE:
			newState = Object.assign( {}, state )
			newState = update( newState, {
				isError: {$set: true},
			});
			return newState

		default:
			return state
  }
}

export default track