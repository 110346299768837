import React from 'react';

import Drawer from '@material-ui/core/Drawer';

import Typography from '@material-ui/core/Typography';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';


import {  NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { fade,withStyles } from '@material-ui/core/styles';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flexGrow: 1,
    },
    menuButton: {
        marginLeft: -12,
        marginRight: 20,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(3),
          width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    projectTypeMenu:{
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
          backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        padding: theme.spacing(1, 3, 1, 7),        
        color: fade(theme.palette.common.white, 0.5),
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
          width: 200,
        },
    },
})

const PageGeneral = (props) => (
    <div className="track">
        <AppBar position="sticky">
            <Toolbar>
                <IconButton className={props.classes.menuButton} color="inherit" aria-label="Menu"
                    onClick={props.toggleDrawer(true)}>
                    <FontAwesomeIcon icon="bars" />
                </IconButton>   
                <Typography variant="h6" color="inherit" className={props.classes.flex} >
                    {props.project.name}
                </Typography>             
                <div className={props.classes.search}>
                    <div className={props.classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        onChange={props.handleChangeSearch}
                        classes={{
                            root: props.classes.inputRoot,
                            input: props.classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'Search' }}
                    />

                </div>
                <FormControl variant="filled" className={props.classes.formControl}>
                    <Select
                        classes={{
                            root: props.classes.projectTypeMenu,
                        }}
                        value={props.projectType}
                        onChange={props.handleChangeTypeId}
                        inputProps={{
                            name: 'type_id',
                            id: 'projectTypeMenu',
                        }}>

                        <MenuItem value="">
                            <em>Project Type</em>
                        </MenuItem>
                        {Object.keys(props.projectTypeList).map( (i) => {
                                let type = props.projectTypeList[i]
                                return <MenuItem key={i} value={type.id}>{type.name}</MenuItem>
                            })}             
                    </Select>
                </FormControl>

                <Button color="inherit"><NavLink to="/">Projects</NavLink></Button>
                <Button color="inherit"><NavLink to="/logout">Logout</NavLink></Button>
            </Toolbar>
        </AppBar>
        <Drawer
            anchor="left"
            open={props.drawerOpen}
            onClose={props.toggleDrawer(false)}
            classes={{
                paper: 'drawerPaper',
            }} >
            <div
                tabIndex={0}
                role="button"
                onClick={props.toggleDrawer(false)}
                onKeyDown={props.toggleDrawer(false)}
            >
                {props.sidebar}
            </div>            
        </Drawer>
        <main className="container-fluid">
            {props.main}
        </main>
    </div>
);

export default withStyles(styles)(PageGeneral);