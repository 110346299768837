import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom'

import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware } from 'redux'

import kaizenApp from './reducers'

import { history } from './helpers/history';
import App from './components/App';

import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const loggerMiddleware = createLogger()


let store = createStore(
	kaizenApp,
	applyMiddleware(
    	thunkMiddleware, // lets us dispatch() functions
    	//loggerMiddleware // neat middleware that logs actions
  	)
)

ReactDOM.render(
	<Provider store={store}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>, document.getElementById('root'));
