import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { projectActions } from '../../../actions'

import ProjectAdd from './projectAdd/ProjectAdd'

class ProjectAddContainer extends Component { 
	constructor (props) { 		
		super(props);

		this.state = {
			project: this.emptyProject(),			
		}
	}	
	emptyProject(){
		let emptyProject = { 
			name: "",
			type: "",
			url: "",
		}
		return emptyProject;
	}

	setEmptyProject(){
		let newState = update( this.state, {
			project: {$set: this.emptyProject()}
		});		
		this.setState( newState );
	}

	handleChange = (event) => {
		const target = event.target;
	   	const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
			
		let newState = update( this.state, {
			project: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleSubmit = (event) => {
		const {dispatch,auth} = this.props

		dispatch( projectActions.add( auth.user, this.state.project) )
     	this.setEmptyProject()
	}

	render(){
		return  <ProjectAdd 
         	project={this.state.project}
			handleChange={this.handleChange}
			handleSubmit={this.handleSubmit} 
         	/>
  	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		auth: state.auth,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectAddContainer);
