import React, {Component} from 'react';
import { connect } from 'react-redux'

import update from 'immutability-helper';

import { paramActions } from '../../actions'
import Param from './param/Param'

class ParamContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			editAction: props.editAction,
			param: ""
		};
	}

	componentWillMount(){
		this.componentWillReceiveProps(this.props)
	}
	componentWillReceiveProps( nextProps ){
		let param = nextProps.param
		let editAction = nextProps.editAction

		let newState = update( this.state, {
			param: {$set: param},
			editAction: {$set: editAction}
		})

		this.setState( newState )
	}

	handleChange = (event) => {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			param: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

        dispatch(paramActions.saveField( this.state.param.id, name, value )) 		
  	}
	

	render(){
		const {param,editAction} = this.state
		if( !param ) return false
		return <Param 
			editAction={editAction}	
			handleChange={this.handleChange}
			handleSelectChange={this.handleSelectChange}
			handleBlur={this.handleBlur}
			param={param} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  		auth: state.auth,
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ParamContainer);
