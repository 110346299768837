import React from 'react';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles'


const styles = theme => ({
	root: {
		margin: theme.spacing(2)
	},
  	textField: {
   		marginBottom: theme.spacing(4),
  	},  	
});

const ProjectAdd = (props) => {
	const {project,handleChange,handleSubmit,classes} = props

	return <div className={classes.root}>
		<TextField
			label="Name"
	    	className={classes.textField}
	    	name="name"
	    	placeholder="Name" 
		    onChange={handleChange} 
		    value={project.name} />
		<br/>
		<button className="btn btn-outline-secondary" type="button" 
				onClick={handleSubmit}>Add Project</button>
	</div>
}

export default withStyles(styles)(ProjectAdd);