import {
  RESPONSE_LOAD_REQUEST,
  RESPONSE_LOAD_SUCCESS,
  RESPONSE_LOAD_FAILURE,
  RESPONSE_LOAD_BY_PROJECT_ID_REQUEST,
  RESPONSE_LOAD_BY_PROJECT_ID_SUCCESS,
  RESPONSE_LOAD_BY_PROJECT_ID_FAILURE,
  RESPONSE_ADD_REQUEST,
  RESPONSE_ADD_SUCCESS,
  RESPONSE_ADD_FAILURE,
  RESPONSE_SAVE_REQUEST,
  RESPONSE_SAVE_SUCCESS,
  RESPONSE_SAVE_FAILURE,
  RESPONSE_DELETE_REQUEST,
  RESPONSE_DELETE_SUCCESS,
  RESPONSE_DELETE_FAILURE,
} from '../actions'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const response = (state = [], action) => {
	let newState = ""
	let rows = ""
	switch (action.type) {
		case RESPONSE_LOAD_REQUEST:
		case RESPONSE_LOAD_BY_PROJECT_ID_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case RESPONSE_LOAD_SUCCESS:
		case RESPONSE_LOAD_BY_PROJECT_ID_SUCCESS:
			let responseListLoad = {}
			action.rows.map((response) => {
				responseListLoad[response.track_id] = responseListLoad[response.track_id] || {}
				responseListLoad[response.track_id][response.id] =	response
				return true;
			})

			newState = Object.assign( {}, state )
			if( action.rows ){				 				
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: responseListLoad},
				});
			}
			return newState 

		case RESPONSE_LOAD_FAILURE:
		case RESPONSE_LOAD_BY_PROJECT_ID_FAILURE:
			return {};

		case RESPONSE_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case RESPONSE_ADD_SUCCESS:
			rows = Object.assign({},action.rows)
			let responseListAdd = state.list
			responseListAdd[rows.track_id] = responseListAdd[rows.track_id]|| {}
			responseListAdd[rows.track_id][rows.id] = rows
			
			newState = update( state, {
				isSaving: {$set: false},
				list: {$set: responseListAdd}
			});
			return newState 

		case RESPONSE_ADD_FAILURE:
			return {};

		case RESPONSE_SAVE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case RESPONSE_SAVE_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {
				  	[rows.track_id] :{
					 	[rows.id]: {$set: rows}
				  	}
				},
			})
			return newState 

		case RESPONSE_SAVE_FAILURE:
			return {};

		case RESPONSE_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case RESPONSE_DELETE_SUCCESS:
			rows = Object.assign({},action.rows)

			newState = update( state, {
				isSaving: {$set: false},
				list: {
					[rows.track_id]: {$unset: [rows.id]}
				}
			})
			return newState 		

		case RESPONSE_DELETE_FAILURE:
			return {};

		default:
			return state
  }
}

export default response