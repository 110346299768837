import React, {Component} from 'react';
import { connect } from 'react-redux'

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { trackActions } from '../../actions'
import update from 'immutability-helper';



class TrackEncryptContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
			password: ""
		}
	}

	handleChangePassword = (event) => {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;

		let newState = update( this.state, {
			password: {$set: value},
		});

		this.setState( newState );
	}

	handleClick = () => {
		this.setState({
			open: true
		})
	}
	handleClose = () => {
		this.setState({
			open: false
		})
	}
	handleEncrypt = () => {
		const {dispatch} = this.props
		const {password} = this.state
		if( !password ) return false
       	dispatch( trackActions.encrypt(this.props.id, password))
       	this.handleClose();
	}

	

	render(){	
		if( !this.props.id ) return false

		return <div style={{display: "inline"}}>
		<IconButton onClick={this.handleClick} >
			<FontAwesomeIcon icon="lock" size="xs"/> 
		</IconButton>
		<Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        	<DialogTitle id="form-dialog-title">Encrypt</DialogTitle>
        	<DialogContent>
          		<DialogContentText>
            		To encrypt this note, you shoud submit a password. 
            		With this password will be the only way you can read the note.
          		</DialogContentText>
          		<form autoComplete="off">
          		<TextField
            		autoFocus
            		margin="dense"
            		id="password"
            		label="Password"
            		type="password"
            		onChange={this.handleChangePassword}
            		value={this.password}
            		fullWidth
          		/>
          		</form>
        	</DialogContent>
        	<DialogActions>
          		<Button onClick={this.handleClose} color="primary">
            		Cancel
          		</Button>
          		<Button onClick={this.handleEncrypt} color="primary">
            		Encrypt
          		</Button>
        	</DialogActions>
      	</Dialog>
      	</div>
	}
}

const mapStateToProps = (state, ownProps) => {
	return {

	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackEncryptContainer);