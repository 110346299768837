import React, {Component} from 'react';
import { connect } from 'react-redux'

import { projectTypeActions } from '../../../actions'


import ProjectType from './projectType/ProjectType'

class ProjectTypeContainer extends Component { 
	
	constructor(props) {
		super(props);

		this.state = {
			projectType: "",
		}
	}

	componentWillMount(){

		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch} = this.props	
		
		if( !nextProps.projectTypeState
			|| (!nextProps.projectTypeState.isFetching && !nextProps.projectTypeState.list )  ){
			dispatch( projectTypeActions.load() )	
			return true		
		}

		if( (!nextProps.projectTypeState.isFetching && nextProps.projectTypeState.list)
			&&
			nextProps.id !== 0 && nextProps.id !== this.state.projectType.id ){
			const iId = nextProps.id

			let oT = Object.keys(nextProps.projectTypeState.list)
				.map( i => nextProps.projectTypeState.list[i] )
				.filter( pt => {
					return pt.id === iId
				} )

			if( oT ){
				this.setState({
					projectType: oT[0]
				})
			}

		}
	}


	render(){
		const {projectType} = this.state
		
		if( !projectType  ) return false		

		
		return <ProjectType projectType={projectType} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
	  	projectTypeState: state.projectType,   
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTypeContainer);
