import React, {Component} from 'react';
import { connect } from 'react-redux'

import { EditorState } from 'draft-js';

import update from 'immutability-helper';

import { responseActions } from '../../../actions'
import Response from './response/Response'

class ResponseContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			responseEditOpen: false,
			responseExpanded: false,
			response: "",
			descriptionState: EditorState.createEmpty(),
		};
	}

	componentWillMount( ){
		this.componentWillReceiveProps(this.props)
	}
	componentWillReceiveProps( nextProps ){
		let response = nextProps.response
		let editAction = nextProps.editAction

		let responseHtml = ""
		try {
			responseHtml = JSON.parse(response.description)
		} catch (e) {
			responseHtml = response.description //EditorState.createEmpty()
		}

		let	paramResponseList = {}
		if( nextProps.paramResponse.list && nextProps.paramResponse.list[nextProps.response.track_id]  ){
			paramResponseList = nextProps.paramResponse.list[nextProps.response.track_id]
		}
		if(paramResponseList){
			paramResponseList = Object.keys(paramResponseList).map( (i) => {
				if(paramResponseList[i].response_id === response.id){
					return paramResponseList[i]
				}
				return false
			})	
		}

		const paramList = Object.assign({},this.props.paramList)
		let paramContainerList = Object.keys(paramList).map( (i) => { 
            let pl = ""            
            
            paramList[i].response = {
            	param_id: paramList[i].id ,
            	response_id: response.id
            }

            Object.keys(paramResponseList).map( (j) => {
                if( paramResponseList[j] 
                    && paramResponseList[j].response_id === response.id 
                    && paramResponseList[j].param_id === paramList[i].id ){
                    paramList[i].response = paramResponseList[j]
                }

                pl = Object.assign({},paramList[i])
                return true
            } )   
            
            if( pl ){
            	return pl 
            }
            
            return paramList[i]           
        })

		response.param = Object.assign([],paramContainerList)

		let newState = update( this.state, {
			response: {$set: response},
			descriptionState: {$set: responseHtml },
			editAction: {$set: editAction},
		})

		this.setState( newState )
	}

	handleResponseEditOpen = () => {
    	this.setState({ responseEditOpen: true });
  	}

  	handleResponseEditClose = () => {
    	this.setState({ responseEditOpen: false });
  	}

	handleResponseExpand= () => {
    	this.setState(state => ({ responseExpanded: !state.responseExpanded }));
  	};

	handleChange = (event) => {
		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		let newState = update( this.state, {
			response: { [name]: {$set: value} },
		});

		this.setState( newState );
	}

	handleBlur = ( event ) => {
		const {dispatch} = this.props

  		const target = event.target;
	    const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

        dispatch(responseActions.saveField( this.state.response.id, name, value )) 		
  	}
	

	handleContentStateChange: Function = (contentState) => {
		let newState = update( this.state, {
			descriptionState: {$set: contentState },
			response: { description: {$set: JSON.stringify(contentState) } },
		})

		this.setState( newState );
  	}

  	handleContentStateBlur: Function = (event) => {
		const {dispatch} = this.props

        dispatch(responseActions.saveField( this.state.response.id, "description", this.state.response.description) )
  	}

	render(){
		const {response,descriptionState,paramResponseList,responseEditOpen,responseExpanded} = this.state
		if( !response ) return false
		return <Response 
			responseEditOpen={responseEditOpen}
			handleResponseEditOpen={this.handleResponseEditOpen}
			handleResponseEditClose={this.handleResponseEditClose}
			responseExpanded={responseExpanded}
			handleResponseExpand={this.handleResponseExpand}
			handleChange={this.handleChange}
			handleSelectChange={this.handleSelectChange}
			handleBlur={this.handleBlur}
			handleContentStateChange={this.handleContentStateChange}
			handleContentStateBlur={this.handleContentStateBlur}
			descriptionState={descriptionState}
			paramList={this.props.paramList}
			paramResponseList={paramResponseList}
			response={response} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  		auth: state.auth,
  		paramResponse: state.paramResponse
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseContainer);
