import React, {Component} from 'react';
import { connect } from 'react-redux'

import TrackMenu from './trackMenu/TrackMenu'

import {sortProjectList} from '../../functions/';

import { projectActions } from '../../actions'

class TrackMenuContainer extends Component { 

	constructor(props) {
		super(props);

		this.state = {
			projectId: ""	
		}
	}

	componentWillMount(){
		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch} = nextProps

		this.setState({
			projectId: nextProps.projectId})

		if( !nextProps.project.list){
			dispatch( projectActions.load() )
		}
	}

	render(){
		const {projectType} = this.props
		
		let projectList = sortProjectList( this.props.project.list)
		
		if(projectType){
			projectList = projectList.filter( (p) => (projectType === p.type_id ))
		}

		return  <TrackMenu 
            	projectId={this.state.projectId}
				projectList={projectList}
			/>
  	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
		project: state.project, 
  	}
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
	 dispatch
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackMenuContainer);
