import {fetchApi} from '../helpers';

export const responseService = {
	load,
    loadByProjectId,
	add,
    saveField,
    _delete,
};

function load() {
    const res  = fetchApi('api/responseLoad', {
    }) 

    return res
}

function loadByProjectId( projectId ) {
    const res  = fetchApi('api/responseLoadByProjectId', {
        projectId: projectId
    }) 

    return res
}

function add( track ) {
    const res  = fetchApi('api/responseAdd', {
        track: track,
    }) 

    return res
}

function saveField(id,name,value) {
    const res  = fetchApi('api/responseSaveField', {
        id: id,
        name: name,
        value: value,
    }) 

    return res
}

function _delete(id) {
    const res  = fetchApi('api/responseDelete', {
        id: id,
    }) 

    return res
}