import {
  PARAM_LOAD_REQUEST,
  PARAM_LOAD_SUCCESS,
  PARAM_LOAD_FAILURE,
  PARAM_LOAD_BY_PROJECT_ID_REQUEST,
  PARAM_LOAD_BY_PROJECT_ID_SUCCESS,
  PARAM_LOAD_BY_PROJECT_ID_FAILURE,
  PARAM_ADD_REQUEST,
  PARAM_ADD_SUCCESS,
  PARAM_ADD_FAILURE,
  PARAM_SAVE_REQUEST,
  PARAM_SAVE_SUCCESS,
  PARAM_SAVE_FAILURE,
  PARAM_DELETE_REQUEST,
  PARAM_DELETE_SUCCESS,
  PARAM_DELETE_FAILURE,
} from '../actions/param'

import update from 'immutability-helper';

//let user = JSON.parse(localStorage.getItem('user'));
//const initialState = true ? { loggedIn: true } : {};

const param = (state = [], action) => {
	let newState = ""
	let rows = ""
	switch (action.type) {
		case PARAM_LOAD_REQUEST:
		case PARAM_LOAD_BY_PROJECT_ID_REQUEST:
			newState = update( state, {
				isFetching: {$set: true},					
			});
			return newState 
		case PARAM_LOAD_SUCCESS:
		case PARAM_LOAD_BY_PROJECT_ID_SUCCESS:
			const paramList = {}
			action.rows.map((param) => {
				paramList[param.track_id] = paramList[param.track_id] || {}
				paramList[param.track_id][param.id] =	param
				return true;
			})

			newState = Object.assign( {}, state )
			if( action.rows ){				 				
				newState = update( newState, {
					isFetching: {$set: false},
					list: {$set: paramList},
				});
			}
			return newState 

		case PARAM_LOAD_FAILURE:
		case PARAM_LOAD_BY_PROJECT_ID_FAILURE:
			return {};

		case PARAM_ADD_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PARAM_ADD_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {
					  	[row.id] : {$set: row}
					},
				});
			}
			return newState 

		case PARAM_ADD_FAILURE:
			return {};

		case PARAM_SAVE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PARAM_SAVE_SUCCESS:
			rows = Object.assign({},action.rows)
			newState = update( state, {
				isSaving: {$set: false},
				list: {
				  	[rows.track_id] :{
					 	[rows.id]: {$set: rows}
				  	}
				},
			})
			return newState 

		case PARAM_SAVE_FAILURE:
			return {};

		case PARAM_DELETE_REQUEST:
			newState = update( state, {
				isSaving: {$set: true},					
			});
			return newState 
		case PARAM_DELETE_SUCCESS:
			rows = Object.assign({},action.rows)
			if( rows.kaizenDay ){
				let row = rows.kaizenDay
				newState = update( state, {
					isSaving: {$set: false},
					list: {$uset: row.id},
				});
			}
			return newState 

		case PARAM_DELETE_FAILURE:
			return {};

		default:
			return state
  }
}

export default param