import React, {Component} from 'react';
import { connect } from 'react-redux'

import { projectActions,projectTypeActions,trackActions } from '../../actions'

import PageGeneral from './pageGeneral/PageGeneral';


class PageGeneralContainer extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			drawerOpen: false,
			projectList: "",
			projectId: "",	
			project: "",
			projectType: "",
		}
	}
	

	componentWillMount(){
		this.componentWillReceiveProps(this.props)
	}

	componentWillReceiveProps( nextProps ){
		const {dispatch,projectId,project} = nextProps

		let pro = ""
		if( projectId && project && project.list ){
			Object.keys(project.list).map( (i) => {
				if( parseInt(project.list[i].id,10) === parseInt(projectId,10) ){
					pro = project.list[i]
				}
				return false
			})
		}

		this.setState({
			projectId: nextProps.projectId,
			projectList: nextProps.projectList,
			project: pro
		})

		if( !nextProps.project.list && !nextProps.project.isError ){
			dispatch( projectActions.load() )
		}

		if( !nextProps.projectTypeState
			|| (!nextProps.projectTypeState.isFetching && !nextProps.projectTypeState.list )  ){
			dispatch( projectTypeActions.load() )			
		}
	}

	toggleDrawer = (open) => () => {
    	this.setState({
      		drawerOpen: open,
    	})
  	}
	
	handleChangeSearch = (event) => {
		const {dispatch} = this.props	

		const target = event.target
	    const value = target.value

		dispatch( trackActions.loadByProjectIdFilter( this.state.projectId, value ) )
	}

	handleChangeTypeId = (event) => {
		const {dispatch} = this.props	

		const target = event.target
	    const value = target.value

	    this.setState({
	    	projectType: value
	    })

		//dispatch( trackActions.loadByProjectId( this.state.projectId, value ) )
	}

	render(){
		const {drawerOpen,projectType} = this.state

		const {projectTypeState} = this.props

		if( !projectTypeState  || !projectTypeState.list ) return false		

		var clonedSidebar = React.cloneElement(
    		this.props.sidebar, 
    		{ projectType: projectType }
		);
		var clonedMain = React.cloneElement(
    		this.props.main, 
    		{ projectType: projectType }
		);

		return <PageGeneral 
			drawerOpen={drawerOpen}
			toggleDrawer={this.toggleDrawer}
			handleChangeSearch={this.handleChangeSearch}
			handleChangeTypeId={this.handleChangeTypeId}
			sidebar={clonedSidebar}
			main={clonedMain}
			project={this.state.project}
			projectType={projectType}
			projectTypeList={projectTypeState.list} />
	}
}

const mapStateToProps = (state, ownProps) => {
  	return {
  		auth: state.auth,
  		project: state.project,
  		projectTypeState: state.projectType
  	};
}
 
const mapDispatchToProps = (dispatch, ownProps) => {
  	return {
		dispatch
  	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PageGeneralContainer);