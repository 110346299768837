import React, {Component} from 'react';
import { Editor } from 'react-draft-wysiwyg';

import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import Fab from '@material-ui/core/Fab';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import TrackDeleteContainer from '../../TrackDeleteContainer'
import TrackEncryptContainer from '../../TrackEncryptContainer'

import ParamAddContainer from '../../../paramAddContainer/ParamAddContainer'
import ParamContainer from '../../../paramContainer/ParamContainer'

import ResponseContainer from '../../../response/responseContainer/ResponseContainer'
import ResponseAddContainer from '../../../response/responseAddContainer/ResponseAddContainer'


import './Track.css';

var moment = require("moment");

const styles = theme => ({
    colTrack: {
        padding: theme.spacing(4)
    },
    card: {
        maxWidth: 400,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
           duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    textField: {
        marginTop: theme.spacing(),
        marginBottom: theme.spacing(),
    },
    fab: {
        position: 'fixed',
        top: theme.spacing(8),
        right: theme.spacing(2),
        margin: theme.spacing(1),
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
    },
});


class Track extends Component {  
 
	render() {
        const { classes } = this.props;

		if( !this.props.track) return false

        const {track,paramList,responseList,
            handleChange,handleContentStateChange,
            responseAddOpen, handleResponseAddOpen, handleResponseAddClose,
            handleContentStateBlur,descriptionState,handleBlur,
            decrypt, openDecrypt, handleClickDecrypt, handleCloseDecrypt, handleDecrypt,
            handleChangePassword, password
            } = this.props



        let sizeTrack = 11;
        let sizeResponse = 1;

        if(responseList.length > 0 ){
            sizeTrack = 7;
            sizeResponse = 5;
        }

		return <div>
            <div className="row">
                <div className={classnames("col-"+ sizeTrack ,classes.colTrack)}>                    
                    <Card>                        
                        <CardContent>
                            <div className="row">
                                <div className="col-10">
                                    <TextField
                                        className={classnames("w-100",classes.textField)}
                                        name="title"
                                        label=""
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        value={track.title} 
                                    /> 
                                </div>
                                <div className="col-2" style={{textAlign: "center"}}>
                                    <TrackEncryptContainer id={track.id}/>
                                    <TrackDeleteContainer id={track.id}/>
                                </div>
                            </div>

                            <div  style={{marginTop: '12px',marginBottom: '24px',width: '100%',height: '350px'}}>
                                {(!track.encrypted || decrypt)?<Editor
                                    id="track-edit"
                                    toolbarHidden={true}
                                    toolbar={{
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                    }}
                                    initialContentState={descriptionState}
                                    wrapperClassName="w-100 h-100" 
                                    editorClassName="editor-class"
                                    toolbarClassName="toolbar-class"
                                    onContentStateChange={handleContentStateChange}
                                    onBlur={handleContentStateBlur}
                                    />:<div style={{textAlign: "center",marginTop: "48px"}}>
                                        <Fab onClick={handleClickDecrypt}>
                                            <FontAwesomeIcon icon="unlock" />
                                        </Fab>
                                    </div>
                                }
                            </div> 
                            <div className="row">
                                <div className="col-9">
                                    <TextField
                                        className={classnames("w-100",classes.textField)}
                                        label="Url"
                                        name="url"
                                        id="margin-none"
                                        helperText=""
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        value={track.url} 
                                    />
                                </div>
                                <div className="col-3"> 
                                    <TextField
                                        name="date"
                                        label="Date"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        className={classnames(classes.textField)}
                                        onChange={handleChange}
                                        onBlur={handleBlur} 
                                        value={moment(track.date).format(moment.HTML5_FMT.DATE)} 
                                    />
                                </div>
                            </div>                            
                        </CardContent>
                        <Table className="w-100">
                            <TableBody>
                                {Object.keys(paramList).map( (p) => {
                                    return <ParamContainer key={"paramEdit" + p} editAction={true} param={paramList[p]} />
                                })}
                            </TableBody>
                            <TableFooter><ParamAddContainer trackId={track.id} /></TableFooter>
                        </Table>                         
                    </Card>
                    
                </div>
                <div className={classnames("col-"+ sizeResponse ,classes.colTrack)}>                        
                    {Object.keys(responseList).map( (i) => {
                            let response = responseList[i]
                            return <ResponseContainer key={response.id}
                                    editAction={false} response={response} 
                                    paramList={paramList} />
                    })}
                    <Fab color="secondary" aria-label="Add" 
                        size="small"
                        className={classes.fab}
                        onClick={handleResponseAddOpen}>
                        <FontAwesomeIcon icon="plus" />
                    </Fab> 
                </div>
            </div>            

            <Dialog
                open={responseAddOpen}
                onClose={handleResponseAddClose}
                scroll="body"
                maxWidth="md">
                <DialogContent>
                    <ResponseAddContainer 
                        trackId={track.id} 
                        paramList={paramList}
                        handleClose={handleResponseAddClose} />
                </DialogContent>                
            </Dialog>
            <Dialog open={openDecrypt} onClose={handleCloseDecrypt} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Decrypt</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Introduce the password to read the note.
                    </DialogContentText>
                    <form  autoComplete="off">
                    <input style={{display:"none"}} type="password" name="fakepasswordremembered" />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Password"
                        type="password"
                        onChange={handleChangePassword}
                        value={password}
                        fullWidth
                    />
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDecrypt} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDecrypt} color="primary">
                        Decrypt
                    </Button>
                </DialogActions>
            </Dialog>
    </div>
    				
	}
}

export default withStyles(styles)(Track);  